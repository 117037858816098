/*==
 *== Text content
 *== ======================================= ==*/


.text-content {
  position: relative;
  padding: $section-top-padding 0;
  background: url('/assets/components/themebootstrap/img/bg_proizvod.jpg') no-repeat 50% 0;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -14px;
    content: '';
    pointer-events: none;
    border: 14px solid transparent;
    border-top-color: #fc8019;
  }

  &__container {
    padding: 0 $section-side-padding;
  }

  &__main {
    margin-top: 30px;
    font-size: 15px;
    column-width: 400px;
    column-count: 2;
    column-gap: 50px;
  }

  @include media-breakpoint-down(sm) {
    @include make-margins();
  }
}
