/*==
 *== Activity buildings
 *== ======================================= ==*/

.activity-buildings {
  position: relative;
  z-index: 1;
  padding-top: $section-top-padding;
  padding-bottom: 15px;

  &__list {
    @include make-row();
  }

  &__item {
    @include make-col-ready();
    @include make-col(6);
    margin-top: 30px;
  }

  @include media-breakpoint-down(sm) {
    @include make-margins();
    @include make-gutters();
    margin-top: -15px;
  }

  @include media-breakpoint-up(sm) {
    &__item {
      @include make-col(4);
    }
  }

  @include media-breakpoint-up(lg) {
    &__item {
      @include make-col(1, 6);
    }
  }
}
