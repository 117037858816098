/*==
 *== Prices and form
 *== ======================================= ==*/

.prices-and-form {
  position: relative;
  padding: 0 0 $section-top-padding;
  background: (
    url('/assets/components/themebootstrap/img/stoimost.png') no-repeat 0 0,
    url('/assets/components/themebootstrap/img/bg_forma.png') no-repeat right 0 top -5px,
    #fff
  );

  .heading {
    margin-bottom: 22px;
  }

  &__container {
    padding-right: $section-side-padding;
    padding-left: 40px;
  }

  &__cost,
  &__form {
    padding-top: $section-top-padding;
  }

  &__form {
  }

  .table-cost {
    box-shadow: 0 3px 5px -3px rgba(black, 0.3);
  }

  @include media-breakpoint-down(md) {
    &__form {
      background: #fff;
    }
  }

  @include media-breakpoint-down(sm) {
    @include make-margins();
    &__container {
      padding-left: $section-side-padding;
    }
  }

  @include media-breakpoint-up(lg) {
    //background-size: auto 100%;
  }
}
