/*==
 *== Years menu
 *== ======================================= ==*/

.years {
  margin-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;

  &:last-child {
    margin-top: 30px;
    margin-bottom: 0;
  }

  &__list {
    display: inline-flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    vertical-align: top;
    border-radius: 5px;
    background: #2e5f7a;
  }

  &__item {

  }

  &__link {
    display: block;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 17px;

    &:active,
    &:focus {
      text-decoration: none;
      color: #fff;
    }
  }

  &__item_active &__link,
  &__link:hover {
    text-decoration: none;
    color: #fff;
    background: #6091ac;
  }

  @supports (display: grid) {
    &__list {
      display: grid;
      max-width: 80px * 12;
      margin: auto;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }
  }
}
