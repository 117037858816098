/*==
 *== Order form
 *== ======================================= ==*/

.form-order-ext {
  &__toggle {
    text-align: center;
    font-size: 15px;
    font-weight: bold;

    a {
      text-decoration: underline;
      color: #c60;
    }
  }

  &__params {
    display: none;
  }

  textarea {
    height: 70px;
  }
}

////////////////////////////////

.bgforma {
  min-height: 400px;
  padding: 0 20px;
  background: url('/assets/components/themebootstrap/img/bg_forma.png') no-repeat;
  background-position: 8px 0;

  input {
    margin-top: 12px;
  }

  input::-webkit-input-placeholder {
    color: #333;
  }

  input::-moz-placeholder {
    color: #333;
  }

  input:-moz-placeholder {
    color: #333;
  }

  input:-ms-input-placeholder {
    color: #333;
  }

  textarea::-webkit-input-placeholder {
    color: #333;
  }

  textarea::-moz-placeholder {
    color: #333;
  }

  textarea:-moz-placeholder {
    color: #333;
  }

  textarea:-ms-input-placeholder {
    color: #333;
  }

  input[type="submit"] {
    width: 170px;
    height: 45px;
    margin: 0;
    margin-top: 15px;
    padding: 0;
    border: 0;
    background: url('/assets/components/themebootstrap/img/bg_button.png') no-repeat;
  }

  input[type="submit"]:active {
    margin-left: 2px;
    border: 0 !important;
    background-position: 2px 2px;
  }

  input[type="submit"]:focus {
    border: 0 !important;
  }

  p {
    padding-top: 5px;
    padding-left: 3px;
    font-size: 14px;
  }

  @include media-breakpoint-down(sm) {
    width: 60%;
    margin: 0 auto;
    background: #fff;
  }
}


.upload {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #ddd;

  div {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    text-transform: uppercase;
    color: #fa8b1f;
    border: 2px solid #fa8b1f;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;

    &:active {
      margin-top: 1px;
      margin-left: 1px;
    }
  }
}

.moreparam {
  text-align: center;

  a {
    text-decoration: underline;
    color: #c60;
    font-size: 15px;
    font-weight: bold;
  }
}
