/*==
 *== Contact block
 *== ======================================= ==*/

.contact-info {
  padding: 30px 0;

  &_first {
    padding-top: 0;
    background: url("../img/bg_pagetitle.png") no-repeat 50% 100%;
  }

  &_middle {
    background: url("../img/bg_conmiddle.png") no-repeat 0 0;
  }

  &__title {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;

    span {
      padding: 3px 6px;
      color: #000;
      background: #fa8b1f;
    }

    &_center {
      justify-content: center;
    }
  }

  &__content {
    &_inline {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &__address {
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 15px;

    &::before {
      display: inline-block;
      flex-shrink: 0;
      width: 15px;
      height: 20px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_point.png");
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
    color: #ff8207;
    font-size: 19px;
    font-weight: bold;

    &::before {
      display: inline-block;
      flex-shrink: 0;
      width: 10px;
      height: 26px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_conphone.png");
    }
  }

  &__email-box {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    padding: 0 10px;

    > * {
      margin: 0 10px;
    }
  }

  &__email {
    display: flex;
    align-items: center;

    &_inline {
      display: inline-flex;
      vertical-align: baseline;
    }


    &::before {
      display: inline-block;
      flex-shrink: 0;
      width: 17px;
      height: 14px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_conmail.png");
    }


    a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: underline;
        color: orange;
      }
    }
  }

  &__skype {
    display: flex;
    align-items: center;

    &::before {
      display: inline-block;
      flex-shrink: 0;
      width: 15px;
      height: 14px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_conskype.png");
    }

    a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: underline;
        color: orange;
      }
    }
  }
}
