/*==
 *== News item
 *== ======================================= ==*/

.news-entry {

  &__header {
    position: relative;
    display: flex;
    align-items: baseline;
    width: 100%;
    padding: 8px 0;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 4px;
      content: '';
      @include dd-strip-gradient2(#fccd31);
    }
  }

  &__header-link {
    text-decoration: none !important;
    color: #930;
  }

  &__header-link:hover &__title {
    text-decoration: underline;
  }

  &__date {
    display: inline-block;
    flex-shrink: 0;
    color: orange;
    font-size: 16px;
    font-weight: bold;
  }

  &__title {
    display: block;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  @include media-breakpoint-down(mb) {
    &__side {
      margin-top: 10px;
    }
    &__content {
      margin-top: 15px;
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;

    &__header {
      border-bottom: 1px solid #ebebeb;
    }

    &__header-link {
      display: flex;
      align-items: baseline;
    }

    &__title {
      padding: 0 0 0 20px;
    }

    &__side {
      flex-basis: percentage(1/3);
      width: percentage(1/3);
      max-width: percentage(1/3);
    }

    &__content {
      flex-basis: percentage(2/3);
      width: percentage(2/3);
      max-width: percentage(2/3);
      padding-top: 10px;
      padding-right: 30px;
      padding-left: 20px;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    @supports (display: grid) {
      position: relative;
      display: grid;
      align-content: start;
      column-gap: 30px;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto 1fr;
      // @formatter:off
      grid-template-areas:
        'side header'
        'side content';
      // @formatter:on

      &__header {
        grid-area: header;
        position: static;
      }
      &__title {
        order: -1;
        padding: 0 20px 0 0;
      }
      &__content {
        grid-area: content;
        flex-basis: auto;
        width: auto;
        max-width: none;
        padding-left: 0;
      }
      &__side {
        grid-area: side;
        flex-basis: auto;
        width: auto;
        max-width: none;
        padding-top: 4px;
      }
      &__cover {
        display: block;
        width: 100%;

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
