/*==
 *== Footer
 *== ======================================= ==*/

.footer {
  margin-top: 0;
  padding-bottom: 40px;
  color: rgba(#f0f3f6, 0.95);
  background: $footer-bg;

  &__nav {
    background: darken($footer-bg, 7%);
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__col {
    @include make-col-ready();
    padding-top: 30px;

    @include media-breakpoint-between(sm, md) {
      &:nth-child(1),
      &:nth-child(2) {
        @include make-col(6);
      }
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }

  &__title {
    display: flex;
    margin-bottom: 15px;

    > span {
      padding: 3px 0;
      color: #fff;
      //background: #fa8b1f;
      border-bottom: 3px solid #fa8b1f;
    }
  }

  &__col {
    a {
      padding-right: 20px;
      text-decoration: underline;
      color: inherit;

      &:hover {
        color: #fff;
      }
    }

    p > img {
      opacity: 0.95;
    }

    strong {
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.copyright {
  color: #fff;
}
