/*==
 *== Owl carousel
 *== ======================================= ==*/

.carousel-6x {
  padding: 0 20px;

  .owl-stage {
    display: flex;
  }

  .owl-item {
    display: flex;

    > * {
      flex-grow: 1;
    }
  }

  .owl-nav {
    color: currentColor;
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 85px;
    width: 15px;
    height: 30px;
    text-indent: -9999px;
    font-size: 0;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      content: '';
      border: {
        width: 15px;
        color: transparent currentColor;
        style: solid;
      }
    }
  }

  .owl-prev {
    left: 0;

    &::before {
      border-left-width: 0;
    }
  }

  .owl-next {
    right: 0;

    &::before {
      border-right-width: 0;

    }
  }
}

// todo: remove it

.owl-carousel:not(.carousel-6x) {
  padding: 0 20px;

  .owl-nav .owl-prev {
    position: absolute;
    top: 85px;
    left: 0;
    width: 15px;
    height: 30px;
    background: url('/assets/components/themebootstrap/img/sl_prev.png') no-repeat;
    font-size: 0px;
  }

  .owl-nav .owl-next {
    position: absolute;
    top: 85px;
    right: 0;
    width: 15px;
    height: 30px;
    background: url('/assets/components/themebootstrap/img/sl_next.png') no-repeat;
    font-size: 0px;
  }

  // todo: deprecated
  .item {
    @extend %deprecated;
    padding: 0px 5px;
  }

  .item h4 {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 14px;
  }

  .item h4 span {
    margin-bottom: 5px;
    color: #d3802d;
    font-size: 22px;
    font-weight: bold;
  }

  .item a {
    position: relative;
    left: -3px;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;
    text-transform: none;
    color: #d3802d;
    border: 0;
    font-size: 16px;
  }

  .item p {
    margin: 0;
    padding: 0;
    padding-top: 7px;
    text-align: left;
    text-transform: none;
    color: #333;
    border: 0;
    font-size: 15px;
  }
}


.owl-nav .disabled {
  opacity: 0.5;
}
