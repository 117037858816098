/*==
 *== Prompt
 *== ======================================= ==*/

.prompt {
  margin-right: -$content-gutter;
  margin-right: calc(var(--content-gutter) * -1);
  margin-bottom: 35px;
  margin-left: -$content-gutter;
  margin-left: calc(var(--content-gutter) * -1);
  padding: 15px $content-gutter;
  padding: 15px var(--content-gutter);
  background: #f7f7f7;
  font-style: italic;

  p:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
    color: #d16636;
  }

  .page-content > &:first-child {
    margin-top: -20px;
  }
}
