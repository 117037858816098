@import "settings.scss";
@import "mixins.scss";
@import "webfonts.scss";

%deprecated {
  outline: 3px dotted red !important;
  outline-offset: -3px !important;
}

.deprecated {
  @extend %deprecated;
}

:root {
  --content-gutter: #{$content-gutter-xs};
  @include media-breakpoint-up(sm) {
    --content-gutter: #{$content-gutter-sm};
  }
  @include media-breakpoint-up(lg) {
    --content-gutter: #{$content-gutter};
  }
}

@import "blocks/strip-line.scss";
@import "blocks/page-inner.scss";
@import "blocks/topbanner.scss";
@import "blocks/effect5.scss";
@import "blocks/owl-carousel-customize.scss";
@import "blocks/activitiy-buildings.scss";
@import "blocks/activitiy-card.scss";
@import "blocks/fold-card.scss";
@import "blocks/typical.scss";
@import "blocks/factory-promo.scss";
@import "blocks/principles.scss";
@import "blocks/benefits.scss";
@import "blocks/projects.scss";
@import "blocks/prices-and-form.scss";
@import "blocks/table-cost.scss";
@import "blocks/order-form.scss";
@import "blocks/buttons.scss";
@import "blocks/text-content.scss";
@import "blocks/breadcrumb.scss";
@import "blocks/news-slide.scss";
@import "blocks/news-carousel.scss";
@import "blocks/readmore.scss";
@import "blocks/heading.scss";
@import "blocks/fast-form.scss";

@import "add.scss";
@import "embed-responsive.scss";
@import "forms.scss";

/*==
 *== Main styles
 *== ======================================= ==*/

body {
  position: relative;
  max-width: 100%;
  font-family: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;

  @include webkit-scrollbar(#e97d2a, #653215, 5px)
}

.clearfix:after,
.clearfix:before {
  display: table;
  clear: both;
  width: 100%;
  content: "";
}

@include nn-mark-not-loaded-images();

img,
figure {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.fa {
  color: inherit;
}

.icon-calendar {
  width: 18px;
  height: 18px;
  background-image: url(../img/sprite/sprite.png);
  background-position: 0px 0px;
}

.icon-comment {
  width: 18px;
  height: 18px;
  background-image: url(../img/sprite/sprite.png);
  background-position: 0px -20px;
}

.icon-mark {
  width: 11px;
  height: 18px;
  background-image: url(../img/sprite/sprite.png);
  background-position: 0px -40px;
}

[class^="icon-"] {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.icon-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-svg--bike {
  width: 1em;
  font-size: 3.2rem;
}

.icon-svg--facebook {
  width: 1em;
  font-size: 2rem;
}

.icon-svg--tuxedo {
  width: 1em;
  font-size: 3.2rem;
}

.icon-svg {
  vertical-align: middle;
}

.icon-svg--bike {
  color: red;
  font-size: 50px;
}

.icon-svg--tuxedo {
  color: blue;
  font-size: 30px;
}

.icon-svg--facebook {
  color: aqua;
  font-size: 30px;
}

label.checkbox {
  position: relative;
  width: 100%;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  color: #000;
  font-size: 15px;
  line-height: 1.5em;
}

label.checkbox i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 17px;
  text-align: center;
  vertical-align: middle;
  color: transparent;
  border: 1px solid #000;
  border-radius: 3px;
  font-size: 15px;
  line-height: 25px;
}

label.checkbox span {
  display: inline-block;
  width: calc(100% - 25px - 17px);
  vertical-align: middle;
}

label.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

label.checkbox input:checked ~ i {
  color: #4b84f5;
  background-color: #fff;
}

label.radio {
  position: relative;
  width: 100%;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  color: #000;
  font-size: 15px;
  line-height: 1.5em;
}

label.radio i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 20px;
  vertical-align: middle;
  border: 1px solid #000;
  border-radius: 50%;
}

label.radio span {
  display: inline-block;
  width: calc(100% - 25px - 17px);
  vertical-align: middle;
}

label.radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

label.radio input:checked ~ i:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 9px;
  height: 9px;
  margin: auto;
  content: "";
  border-radius: 50%;
  background-color: #000;
}

*::-webkit-input-placeholder,
*:-moz-placeholder,
*::-moz-placeholder,
*:-ms-input-placeholder {
  opacity: 1;
}

@import "blocks/icon-contact.scss";
@import "blocks/header.scss";
@import "blocks/header-button.scss";
@import "blocks/header-caption.scss";

.mobile-contact {
  position: relative;
  display: none;
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #eee;
  background: #fff;
  box-shadow: 0 2px 4px rgba(#000, 0.3);
}

@include media-breakpoint-up(md) {
  .mobile-contact {
    display: none !important;
  }
}

.mobile-contact:before {
  position: absolute;
  top: -17px;
  left: 50%;
  content: "\25b2";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  font-size: 20px;
}

.c-yellow {
  color: #ed7a20;
}

@import "blocks/mobile-menu.scss";
@import "blocks/gallery.scss";
@import "blocks/footer-menu.scss";
@import "blocks/footer.scss";
@import "blocks/section-feedback-form.scss";
@import "blocks/achievements.scss";
@import "blocks/reviews.scss";
@import "blocks/entry.scss";
@import "blocks/advantages.scss";
@import "blocks/warranty-block.scss";
@import "blocks/stages.scss";
@import "blocks/card.scss";
@import "blocks/objects.scss";
@import "blocks/object-description.scss";
@import "blocks/photo-gallery.scss";
@import "blocks/years.scss";
@import "blocks/news-entry.scss";
@import "blocks/news-list.scss";
@import "blocks/prompt.scss";
@import "blocks/article.scss";
@import "blocks/article-item.scss";
@import "blocks/all-title.scss";
@import "blocks/projects-table.scss";
@import "blocks/contacts-page.scss";
@import "blocks/contacts-info.scss";
@import "blocks/contacts-map.scss";
@import "blocks/tp-card.scss";

.animate {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.dev {
  //display: none;
  padding: 0.1em 1em;
  color: black;
  border: 1px solid red;
  background: pink;
  font-size: 12px;
  line-height: 1.2;

  .dev-mode & {
    display: block;
  }
}

.dev-mode {
  .container {
    $s: rgba(pink, 0.5);
    background: linear-gradient(to right, $s 10px, transparent 10px, transparent calc(100% - 10px), $s calc(100% - 10px));
    @include media-breakpoint-up(lg) {
      background: linear-gradient(to right, $s 15px, transparent 15px, transparent calc(100% - 15px), $s calc(100% - 15px));
    }
  }

  .inner,
  .page-content {
    $s: rgba(#C2DEB7, 0.5);
    background-image: linear-gradient(
        to right,
        $s var(--content-gutter),
        transparent var(--content-gutter),
        transparent calc(100% - var(--content-gutter)),
        $s calc(100% - var(--content-gutter))
    );
  }
}
