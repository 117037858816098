/*==
 *== Projects table
 *== ======================================= ==*/

#projects-table {
  min-width: 1140px;

  .jsgrid-cell {
    font-size: 12px;
  }

  .jsgrid-header-cell {
    font-size: 12px;

    &.jsgrid-header-sortable {
      position: relative;

      &.jsgrid-header-sort {
        &::before {
          position: absolute;
          bottom: 8px;
          left: 50%;
          margin-left: -2px;
        }

        &.jsgrid-header-sort-asc {
        }
      }
    }
  }

  .jsgrid-filter-row {
    .jsgrid-cell {
      padding: 1px;

      select,
      input[type=text] {
        height: 20px;
        padding: 0;
        text-indent: 10px;
        border: 1px solid #d8d8d8;
        background: #fff;

        &:focus {
          border-color: #d7e3f4;
          outline: none !important;
        }
      }

      select {
        text-indent: 0;
        background: linear-gradient(to bottom, #f5f5f5, #f1f1f1);
      }
    }
  }
}

.projects-table-outer {
  overflow: auto;
  margin-right: ($content-gutter / -1);
  margin-right: calc(var(--content-gutter) / -1);
  margin-left: ($content-gutter / -1);
  margin-left: calc(var(--content-gutter) / -1);
  @include webkit-scrollbar(orange, rgba(orange, 0.5), 8px);
}
