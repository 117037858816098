/*==
 *== News carousel
 *== ======================================= ==*/

.news-carousel {
  position: relative;
  min-height: 394px - 63px;
  padding-top: 30px;

  &::before {
    position: absolute;
    top: -63px;
    left: 0;
    width: 100%;
    height: 394px;
    content: '';
    pointer-events: none;
    background: url('/assets/components/themebootstrap/img/bg_novosti.png') no-repeat 50% 0;
    background-size: 100% auto;
  }

  &__header {
    position: relative;
    margin-bottom: 20px;
  }

  &__carousel {
    position: relative;
  }
}
