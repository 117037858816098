/*==
 *== Factory promo
 *== ======================================= ==*/

.factory-promo {
  position: relative;
  padding-top: $section-top-padding + 15px;
  padding-bottom: $section-top-padding;
  background: (
    url("/assets/components/themebootstrap/img/section-divider.png") no-repeat 50% 0,
    url("/assets/components/themebootstrap/img/bg-top-left-promo.png") no-repeat 0 0,
    white
  );

  $pen-width: 310px;
  $pen-height: 96px;
  $pen-top: -45px;

  &::before {
    position: absolute;
    top: $pen-top;
    left: 30px;
    width: $pen-width;
    height: $pen-height;
    content: '';
    pointer-events: none;
    background: url("/assets/components/themebootstrap/img/pen.png");
  }

  > * {
    position: relative;
  }

  &__content {
    margin-top: 35px;
  }

  &__container {
    padding: 0 $section-side-padding;
  }

  @include media-breakpoint-down(sm) {
    @include make-margins();
    &::before {
      $k: 0.75;
      top: $pen-top * $k;
      width: $pen-width * $k;
      height: $pen-height * $k;
      background-size: 100% 100%;
    }
  }

  @include media-breakpoint-down(mb) {
    &::before {
      $k: 0.4;
      top: $pen-top * $k;
      left: 0;
      width: $pen-width * $k;
      height: $pen-height * $k;
    }
  }
}
