/*==
 *== Custom buttons
 *== ======================================= ==*/

.btn-styled {
  position: relative;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 40px;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: uppercase;
  color: #000;
  border: none;
  border-bottom: 3px solid #d38524;
  border-radius: 5px;
  background: #e58032 radial-gradient(circle, #fcbf1a, #f89926);
  font-size: 15px;
  font-weight: bold;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    content: '';
    transform: rotate(45deg);
    border: 10px solid rgba(white, 0.2);
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-right: -25px;
    content: '';
    transform: rotate(45deg);
    border: 10px solid rgba(white, 0.2);
  }

  span {
    position: relative;
    z-index: 1;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(#e58032, 0.3);
  }

  &:active {
    padding-bottom: 13px;
    border-bottom-width: 0;
  }

  &:hover {
    color: #000;
  }
}

.btn-readmore {
  padding: 1px 10px 3px 10px;
  border: 2px solid orange;
}
