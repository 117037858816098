/*==
 *== News carousel item
 *== ======================================= ==*/

.news-slide {
  padding: 0 5px;

  &__date {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 14px;
  }

  &__day {
    margin-bottom: 5px;
    color: #d3802d;
    font-size: 22px;
    font-weight: bold;
  }

  &__title {
    overflow: hidden;
    height: (22/16) * 4em;
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: none;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: (22/16);

    a {
      display: inline-block;
      text-decoration: underline;
      color: #d3802d;
    }
  }

  &__content {
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: none;
    color: #333;
    border: 0;
    font-size: 15px;
    font-weight: bold;
  }
}
