/*==
 *==
 *== ======================================= ==*/

.breadcrumb {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 8px 0;
  list-style: none;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;

  > li {
    display: inline-block;
  }

  > li + li:before {
    padding: 0 15px;
    content: "/";
    color: #4c4c4c;
  }

  > .active {
    color: #4c4c4c;
  }
}
