/*==
 *== Header
 *== ======================================= ==*/

.header-section {
  position: fixed;
  z-index: $z-header;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(#f9f9f9, 0.95);
  box-shadow: 0 2px 3px rgba(#000, 0);

  .header,
  .header__caption,
  .header__phones,
  .header-button {
    transition: 0.4s ease;
    transition-property: padding, margin, font-size;
    transform-origin: 50% 0;
  }

  &.isFixed {
    box-shadow: 0 2px 3px rgba(#000, 0.3);

    .header {
      padding-top: 7px;
      padding-bottom: 7px;

      &__caption {
        overflow: hidden;
        width: 0;
        height: 0;
        margin-right: 0;
      }

      &__phones {
        font-size: 16px;
      }

      &__shedule {
        br {
          display: none;
        }
      }
    }

    .header-button {
      padding: 12px 16px;
      font-size: 12px;
    }
  }
}

.header-spacer {
  height: 85px;
  @include media-breakpoint-up(sm) {
    height: 91px;
  }
  @include media-breakpoint-up(md) {
    height: 93px;
  }
  @include media-breakpoint-up(lg) {
    height: 157px;
  }
  @include media-breakpoint-up(xl) {
    height: 159px;
  }
}

.header {
  $space: 20px;

  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  &__menu-button {
    flex-shrink: 0;
    margin-right: $space;
  }

  &__logo {
    flex-shrink: 0;
    width: 246px;
    margin-right: $space;

    > a {
      display: inline-block;
      max-width: 100%;
      vertical-align: top;
      text-decoration: none !important;

      > img {
        display: block;
        width: 100%;
      }
    }
  }

  &__caption {
    display: none;
    flex-shrink: 0;
    width: 260px;
    margin-right: $space;
  }

  &__contacts {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin-right: $space;
  }

  &__actions {
    margin-left: auto;
  }

  &__shedule {
    display: none;
    margin-left: ($space / 2);
    padding-left: ($space / 2);
    border-left: 1px dotted #ed7a20;
    font-size: 14px;
    line-height: 20px;
  }

  @include media-breakpoint-down(mb) {
    &__logo {
      width: 216px;
    }
    &__actions {
      position: fixed;
      top: 90px;
      right: 0;
      display: flex;
      transform: translateY(-100%) rotate(-90deg);
      transform-origin: 100% 100%;
      opacity: 0.8;
    }
    &__contacts {
      justify-content: flex-end;
      margin-right: 0;
      margin-left: auto;
    }
  }

  @include media-breakpoint-down(xs) {
    &__logo {
      width: 180px;
    }
  }

  @include media-breakpoint-up(md) {
    &__contact-icon {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    &__shedule {
      display: block;
    }
  }

  @include media-breakpoint-up(xl) {
    &__caption {
      display: block;
    }
  }

  ///////////////////

  &__contacts {
    p {
      margin-bottom: 0;
    }
  }

  &__phones {
    display: block;
    text-align: right;
    font-size: 25px;
    line-height: 1.3;

    a {
      text-decoration: none;
      color: #000;
    }

    .email-mobile {
      display: none;
      text-align: center;
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(md) {
    &__phones {
      border-width: 0;
      font-size: 22px;

      .email-mobile {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__phones {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    &__phones .hidden-mobile {
      display: none;
    }
  }
}
