/*==
 *== Footer menu
 *== ======================================= ==*/

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
  }

  &__link {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    color: #fff;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }

  &__title {
  }
}
