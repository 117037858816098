/*==
 *== Advantages
 *== ======================================= ==*/

.advantages {
  margin: 40px ($content-gutter / -1);
  margin: 40px calc(var(--content-gutter) / -1);

  &__main {
    padding: $content-gutter;
    padding: var(--content-gutter);
    background: url("../img/advantages-bg.jpg") no-repeat 50% 0;
    background-size: cover;
  }

  &__list {
    margin-top: 20px;
  }
}

.advantage-bottom {
  position: relative;
  padding: ($content-gutter * 1.5) $content-gutter;
  padding: calc(var(--content-gutter) * 1.5) var(--content-gutter);
  background: #feba02 radial-gradient(circle, #feba02, #fa8b1f);

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    content: '';
    pointer-events: none;
    @include dd-strip-gradient(rgba(white, 0.37));
  }


  &__content {

  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__icon {
    flex-shrink: 0;
    width: 70px;
  }

  &__box {
    flex-grow: 1;
    padding-right: 20px;
    padding-left: 30px;
  }

  &__text1 {
    font-size: 20px;
    line-height: (25/20);
  }

  &__text2 {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: (25/20);
  }

  @include media-breakpoint-down(md) {
    &__item {
      &:nth-child(n + 2) {
        margin-top: 30px;
      }
    }
  }
}
