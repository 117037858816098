/*==
 *== Achievements
 *== ======================================= ==*/

.achievements {
  margin: $section-top-padding ($content-gutter / -1);
  margin: $section-top-padding calc(var(--content-gutter) / -1);
  padding: $section-top-padding $content-gutter;
  padding: $section-top-padding var(--content-gutter);
  background: url("../img/avievements-bg.jpg") no-repeat 50% 50%;
  background-size: cover;

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
  }

  &__main {
    @include make-row();
    justify-content: center;
    margin-top: 30px;
  }

  &__item {
    @include make-col-ready();

    @include media-breakpoint-down(mb) {
      &:nth-child(n + 2) {
        margin-top: 30px;
      }
    }

    @include media-breakpoint-only(sm) {
      @include make-col(1, 2);
      &:nth-child(n + 3) {
        margin-top: 30px;
      }
    }

    @include media-breakpoint-between(md, lg) {
      @include make-col(1, 3);
      &:nth-child(n + 4) {
        margin-top: 30px;
      }
    }

    @include media-breakpoint-up(xl) {
      @include make-col(1, 5);
      &:nth-child(n + 6) {
        margin-top: 30px;
      }
    }
  }

}

.achievement {
  &__iconbox {
  }

  &__icon {
    display: block;
    width: 100px;
    height: 100px;
    margin: auto;
  }

  &__title {
    margin-top: 5px;
    text-align: center;
    color: #cc6701;
    font-size: 18px;
    font-weight: bold;
  }

  &__desc {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    line-height: (20/14);
  }
}
