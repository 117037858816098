/*==
 *== Photo gallery
 *== ======================================= ==*/
// todo: deprecated

.photo-gallery {
  @extend %deprecated;
  border: 1px solid red;
  background: pink;

  &::before {
    content: '@deprecated .photo-gallery';
  }

  //margin: 35px auto 0;
  ////max-width: (300px * 3 + 30px * 2);
  //
  //&__item {
  //  @include make-col-ready();
  //  @include make-col(1, 2);
  //  margin-bottom: 20px;
  //
  //  @include media-breakpoint-up(lg) {
  //    @include make-col(1, 3);
  //    margin-bottom: 30px;
  //  }
  //}
  //
  //&__box {
  //  width: 100%;
  //  height: 0;
  //  padding-bottom: percentage(225/300);
  //  position: relative;
  //}
  //
  //img {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  height: 100%;
  //  object-fit: cover;
  //}
}
