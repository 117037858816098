/*==
 *== Card
 *== ======================================= ==*/

.card {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid orange;
  border-radius: 5px;
  background: #fdebcd;

  &__imgbox {
    position: relative;
    display: block;
    text-decoration: none;

    &::before {
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: percentage(225/300);
      content: '';
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid orange;
      border-radius: 2px;
      object-fit: cover;
    }
  }

  &__content {
    padding: 5px 0 0;
    font-size: 15px;

    p {
      margin: 3px 0 0;

      > span {
        opacity: 0.7;
        font-size: 0.9em;
      }
    }
  }

  &__title {
    padding: 3px 0;
    font-weight: bold;

    > a {
      text-decoration: underline;
      color: #c96651;

      &:hover {
        text-decoration: underline;
        color: #23527c;
      }
    }
  }
}
