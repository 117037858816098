/*==
 *== Article
 *== ======================================= ==*/

.article {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 18px;

  > :last-child {
    margin-bottom: 0;
  }
}
