/*==
 *== Entry
 *== ======================================= ==*/

.entry {
  //display: flex;

  &__image {
    display: flex;
    flex-shrink: 0;

    a {
      max-width: 100%;

      &:hover img {
        border: 1px solid orange;
        background: orange;
      }
    }

    img {
      max-width: 100%;
      padding: 5px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  }

  &__body {
    flex-grow: 1;
    margin-top: 15px;
    //padding-left: 30px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 20px;
  }

  &__content {

  }

  @include media-breakpoint-up(sm) {
    display: flex;
    &__image {
      width: 150px;
    }
    &__body {
      margin-top: 0;
      padding-left: 20px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__image {
      width: 250px;
    }
    &__body {
      padding-left: 30px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__image {
      width: 300px;
    }
  }
}
