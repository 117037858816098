/*==
 *== Table cost
 *== ======================================= ==*/

.tablecost,
.table-cost {
  background: #fff;

  thead {
    color: #fff;
    background: rgba(#5d7090, 0.65) url('/assets/components/themebootstrap/img/bg_htable.png') repeat-x 0 -1px;
    font-size: 16px;
  }

  th {
    padding: 11px;
    font-weight: normal;
  }

  > thead,
  > tbody {
    > tr {
      > th,
      > td {
        vertical-align: middle;

        &:first-child {
          padding: 11px;
          padding-left: 30px;
        }
      }
    }
  }

  span {
    color: #d78635;
    font-size: 15px;
    font-weight: bold;
  }
}
