/*==
 *== Mobile menu & top line
 *== ======================================= ==*/

$mm-breakpoint-toggle-up: breakpoint-next($mm-breakpoint-toggle);

.line-dashed {
  display: block;
  height: 5px;
  background: #fff url("https://www.izmk.ru/assets/components/themebootstrap/img/poloski.png") repeat-x;
}

body.isMenuOpen {
  @include media-breakpoint-down($mm-breakpoint-toggle) {
    overflow: hidden;

    > .header-section > .container,
    > .header-section > .mobile-contact,
    > .container {
      pointer-events: none;
      filter: blur(3px);
    }
  }
}

.openbtn {
  display: inline-block;
  cursor: pointer;
  background: none;
  box-shadow: none;

  &__iconline {
    display: block;
    width: 30px;
    height: 4px;
    margin: 5px 0;
    border-radius: 2px;
    background: #aaa;
  }

  &:hover > span {
    background: #ddd;
  }

  @include media-breakpoint-up($mm-breakpoint-toggle-up) {
    display: none;
  }
}

.closebtn {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $mm-close-button-height;
  padding: 0 15px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  font-size: 36px;

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  > span:first-child {
    font-size: 14px;
  }

  @include media-breakpoint-up($mm-breakpoint-toggle-up) {
    display: none;
  }
}

.menubar {
  background-color: #d7e5f5;
}

.multimenu {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    position: relative;
  }

  &__link {
    position: relative;
    display: flex;
    color: #337ab7;

    &:focus,
    &:hover {
      text-decoration: none;
      color: #000;
      outline: none;
    }

    &.active {
      color: #fff;
      background: #e97d2a;

      + .multimenu__toggle {
        color: #fff;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: $mm-item-height;
    padding: 5px 15px;
  }

  &__toggle {
    // Стрелка
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: $mm-item-height;
    height: $mm-item-height;
    margin-left: 0;
    cursor: pointer;
    transform: translateX(0);
    color: black;
    border-left: 1px solid $mm-divider;

    i {
      display: block;
      width: 0;
      height: 0;
      border: {
        width: 8px 5px 0;
        color: currentColor transparent transparent;
        style: solid;
      }
    }

    &:hover {
      background: #fff;
    }
  }

  //==
  //== Desktop view
  //== ======================================= ==//

  @include media-breakpoint-up($mm-breakpoint-toggle-up) {
    &__toggle {
      position: absolute;
      top: 0;
      right: 0;
      width: 6px;
      pointer-events: none;
      color: $mm-color;
      border: none;

      i {
        border-width: 5px 3px 0;
      }
    }

    // Выпадашка
    & & {
      position: absolute;
      top: 100%;
      left: 0;
      display: none !important;
      overflow-y: auto;
      width: 300px;
      max-height: 90vh;
      max-height: calc(100vh - 50px);
      background: $mm-background;
      box-shadow: 0 0 3px rgba(black, 0.2);

      .multimenu__title {
        min-height: ($mm-item-height / 1.5);
      }

      .multimenu__togle {
        display: none;
      }
    }
  }

  //==
  //== Mobile view
  //== ======================================= ==//

  @include media-breakpoint-down($mm-breakpoint-toggle) {
    &__item {
      border-bottom: 1px solid $mm-divider;
    }
    &__item.open &__toggle {
      background: #fff;
    }
    & & {
      display: none;
      border-top: 1px solid $mm-divider;
      background: #fff;
    }
  }
}

.navmenu {
  //==
  //== Desktop view
  //== ======================================= ==//

  @include media-breakpoint-up($mm-breakpoint-toggle-up) {
    // Верхний уровень меню
    > .multimenu {
      display: flex;
      justify-content: space-between;
    }

    > .multimenu > .multimenu__item {
      &:hover {
        .multimenu {
          display: block !important;
        }
      }
    }
  }

  //==
  //== Mobile view
  //== ======================================= ==//

  @include media-breakpoint-down($mm-breakpoint-toggle) {
    position: fixed;
    z-index: $z-navmenu;
    top: 0;
    left: ($mm-width / -1);
    width: $mm-width;
    height: 100%;
    transition: left 0.5s ease;
    background-color: $mm-background;

    &.isOpen {
      left: 0;
      box-shadow: 0 0 0 300vw rgba(#000, 0.7);
    }

    // Верхний уровень мобильного меню, общая панелька со скроллом
    > .multimenu {
      position: absolute;
      top: $mm-close-button-height;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      overflow-x: hidden;
      overflow-y: auto;

      > .multimenu__item {
        > .multimenu__link {
          text-transform: uppercase;
        }
      }

      .multimenu {
        .multimenu__title {
          min-height: ($mm-item-height / 1.5);
          padding-left: 26px;
        }

        .multimenu__toggle {
          height: ($mm-item-height / 1.5);
        }
      }
    }
  }
}

//==
//== Menu
//== ======================================= ==//

.multimenu {
  //&__submenu {
  //  display: none;
  //  list-style: none;
  //  margin: 0;
  //  padding: 0;
  //  //background-color: #85b0e1;
  //  //
  //  //li { display: block; }
  //  //
  //  //li ul { background-color: #5d95d6; }
  //}
  //
  //&__submenu &__submenu {
  //  display: none;
  //}
  //
  ////&__submenu-item {
  ////  display: block;
  ////  min-width: 200px;
  ////  padding: 14px 15px;
  ////  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  ////  color: #fff;
  ////
  ////  &:hover {
  ////    background-color: #f3f3f3;
  ////    color: #000;
  ////    text-decoration: none;
  ////  }
  ////}
  //
  //@include media-breakpoint-up(lg) {
  //  &__submenu {
  //  }
  //
  //
  //  // только второй уровень
  //  .navmenu > & > &__item:hover > &__submenu {
  //    display: block;
  //  }
  //
  //  &__link {
  //    &.hasChildren { padding-right: 30px; }
  //
  //  }
  //}
  //
  //@include media-breakpoint-down(md) {
  //  display: block;
  //}
}

.mm-submenu {
  //&__item {
  //  display: block;
  //  border-bottom: 1px solid rgba(black, 0.15);
  //}
  //
  //&__link {
  //  display: flex;
  //  align-items: center;
  //  padding: 10px 15px;
  //  min-height: $mm-item-height;
  //
  //  &:hover {
  //    color: black;
  //    background: #fff;
  //    text-decoration: none;
  //  }
  //}
  //
  //@include media-breakpoint-up(lg) {
  //  box-shadow: 0 0 3px rgba(black, 0.2);
  //
  //  &__link {
  //    &.active {
  //      background: #e97d2a;
  //      color: #fff;
  //    }
  //  }
  //}
}
