/*==
 *== Top banner on home page
 *== ======================================= ==*/

.topbanner {
  position: relative;
  height: auto;
  background: #4c4c4c no-repeat 50% 50%;
  background-size: contain;
  @include make-margins();

  &__mock {
    display: block;
    width: 100%;
    height: auto;
    min-height: 200px;
    opacity: 0.7;
  }

  &__positioner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    max-width: 70%;
    padding: 10px 10px 10px 15px;
    color: #fff;
    border-left: 4px solid #f78e2a;
    background: rgba(black, 0.7);
    text-shadow: 0 1px 1px rgba(#000, 0.5);
  }

  &__title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1;
  }

  &__subtitle {
    text-transform: lowercase;
    font-size: 10px;
  }

  @include media-breakpoint-up(mb) {
    &__title {
      font-size: 19px;
    }
  }

  @include media-breakpoint-up(sm) {
    &__content {
      max-width: 100%;
      background: rgba(black, 0.5);
    }
    &__title {
      font-size: 34px;
    }
    &__subtitle {
      font-size: 15px;
      font-weight: bold;
    }
  }

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}
