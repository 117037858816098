/*==
 *== Feedback form section
 *== ======================================= ==*/

.section-heading {
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
}

.section-feedback-form {
  padding: 50px 0;
  background: #f9f9f9;

  textarea {
    height: 132px;
  }
}
