.fast-form {
  &__title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }

  &__main {
    margin-top: 15px;
  }

  .field-accept {
    margin-top: -20px;
  }

  .upload {
    margin: 0;

    div {
      top: -1px;
      right: -1px;
    }
  }

  @include media-breakpoint-up(md) {
    .form-group {
      margin: 0 !important;
    }
    .field-accept {
      margin-top: 0;
    }
    &__main {
      display: grid;
      grid-gap: 20px;
      grid-template-areas: 'name phone' 'email file' 'submit accept';
    }
    .field-name {
      grid-area: name;
    }
    .field-phone {
      grid-area: phone;
    }
    .field-email {
      grid-area: email;
    }
    .field-file {
      grid-area: file;
    }
    .field-submit {
      grid-area: submit;
    }
    .field-accept {
      align-self: center;
      grid-area: accept;
    }
  }
  @include media-breakpoint-up(lg) {
    &__main {
      grid-template-areas: 'name phone email' 'file submit accept';
    }
    .field-submit {
      justify-self: center;
    }
  }
  @include media-breakpoint-up(lg) {
    &__main {
      align-content: start;
      align-items: start;
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas:
        'name name phone phone email email file file'
        'accept accept accept submit submit . . .';
      //grid-template-rows: auto auto;
    }
    .field-submit {
      //justify-self: start;
    }
  }
}
