/*==
 *== Gallery
 *== ======================================= ==*/

@mixin gallery-columns($column-count) {
  @include make-col(1, $column-count);
  &:nth-child(n + #{$column-count + 1}) {
    margin-top: 30px;
  }
}

.gallery {
  $this: &;

  //==
  //== Layout
  //== ======================================= ==//

  margin-top: 20px;
  margin-bottom: 20px;

  &__item {
    @include make-col-ready();
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(mb) {
      @include gallery-columns(1);
    }
  }

  &:not([data-columns]) &__item,
  &[data-columns="2"] &__item {
    @include media-breakpoint-up(sm) {
      @include gallery-columns(2);
    }
  }

  &[data-columns="3"] &__item {
    @include media-breakpoint-between(sm, md) {
      @include gallery-columns(2);
    }
    @include media-breakpoint-up(lg) {
      @include gallery-columns(3);
    }
  }

  &[data-columns="4"] &__item {
    @include media-breakpoint-between(sm, md) {
      @include gallery-columns(2);
    }
    @include media-breakpoint-only(lg) {
      @include gallery-columns(3);
    }
    @include media-breakpoint-up(xl) {
      @include gallery-columns(4);
    }
  }

  &[data-columns="5"] &__item {
    @include media-breakpoint-between(sm, md) {
      @include gallery-columns(2);
    }
    @include media-breakpoint-only(lg) {
      @include gallery-columns(3);
    }
    @include media-breakpoint-up(xl) {
      @include gallery-columns(5);
    }
  }

  &[data-columns="6"] &__item {
    @include media-breakpoint-between(sm, md) {
      @include gallery-columns(2);
    }
    @include media-breakpoint-only(lg) {
      @include gallery-columns(3);
    }
    @include media-breakpoint-up(xl) {
      @include gallery-columns(6);
    }
  }

  //==
  //== Item inner
  //== ======================================= ==//

  &__title {
    margin-bottom: 10px;
    font-style: italic;
  }

  &__link,
  &__box {
    transition: 0.3s ease;
  }

  &__link {
    position: relative;
    display: block;
    align-self: flex-start;
    width: 100%;
    border: 5px solid white;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #ddd;

    &:hover {
      border-color: orange;
      box-shadow: 0 0 0 1px orange;
    }

    &::before {
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: percentage(3/4);
      //padding-bottom: percentage(390/510);
      content: '';
    }
  }

  &__link:hover &__box {
    background: #eee;
  }

  &__box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
