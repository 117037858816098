/*==
 *== block heading
 *== ======================================= ==*/

.heading {
  font-size: 18px;

  &__content {
    display: block;
    flex-shrink: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;

    h1, h2 {
      margin: 0;
    }

    h1 {
      font-size: 22px;
    }

    h2 {
      text-transform: none;
      font-size: 30px;
    }

    span {
      display: block;
      text-transform: uppercase;
      color: #555;
      font-size: 15px;
      font-weight: bold;
    }
  }

  &__side {
    display: block;
    flex-shrink: 0;
    margin-top: 10px;
    text-align: center;
  }

  @include media-breakpoint-up(mb) {
    &__content {
      h1 {
        font-size: 26px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    &__side {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: flex-end;
      margin-top: 0;
      transform: translateX(-100%);
    }
    &__content {
      padding-right: 110px;

      h1 {
        text-align: left;
        font-size: 36px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 22px;
    &__content {
      font-size: 22px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__content {
      padding: 0 110px;

      h1 {
        text-align: center;
      }
    }
  }
}

///////////////////////// todo: below code is deprecated
.subhead {
  @extend %deprecated;
  width: 100%;
  text-align: center;
}

.subhead span {
  display: inline-block;
  margin-top: 27px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
}

.subhead p {
  display: block;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: bold;
}

.subhead a {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 25px;
  margin-top: 27px;
  padding: 3px 5px;
  text-transform: uppercase;
  color: #ec6920;
  border: 2px solid #ec6920;
  font-size: 13px;
  font-weight: bold;
}

/*	660	*/
@media screen and (max-width: 660px) {
  .subhead {
    width: 100%;
    text-align: center;
  }
  .subhead span {
    display: inline-block;
    margin-top: 27px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }
  .subhead p {
    display: block;
    text-transform: uppercase;
    color: #555;
    font-size: 13px;
    font-weight: bold;
  }
  .subhead a {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 2px;
    margin-top: 27px;
    padding: 3px 5px;
    text-transform: uppercase;
    color: #ec6920;
    border: 2px solid #ec6920;
    font-size: 11px;
    font-weight: bold;
  }
}

//////////////////////////////////////////////

@include media-breakpoint-down(sm) {
  .longtit {
    text-align: left;
  }
  .longtit span {
    margin-top: 27px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  .longtit p {
    font-size: 13px;
    font-weight: bold;
  }
  .longtit a {
    position: absolute;
    top: -10px;
    right: 25px;
    margin-top: 27px;
    padding: 3px 5px;
    text-transform: uppercase;
    color: #ec6920;
    border: 2px solid #ec6920;
    font-size: 11px;
    font-weight: bold;
  }
}

@media screen and (max-width: 660px) {
  .longtit {
    text-align: left;
  }
  .longtit span {
    margin-top: 27px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  .longtit p {
    font-size: 13px;
    font-weight: bold;
  }
  .longtit a {
    position: absolute;
    top: -10px;
    right: 25px;
    margin-top: 27px;
    padding: 3px 5px;
    text-transform: uppercase;
    color: #ec6920;
    border: 2px solid #ec6920;
    font-size: 11px;
    font-weight: bold;
  }
}

@media screen and (max-width: 560px) {
  .longtit {
    text-align: left;
  }
  .longtit span {
    margin-top: 17px;
    padding-left: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  .longtit p {
    font-size: 12px;
    font-weight: bold;
  }
}
