/*==
 *== Object description
 *== ======================================= ==*/

.object-description {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 18px;

  p {
    margin: 3px 0 0;

    &:first-child {
      margin-top: 0;
    }

    > span {
      opacity: 0.7;
      font-size: 0.9em;
    }
  }
}
