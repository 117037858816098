/*==
 *== Icon contact
 *== ======================================= ==*/

.icon-contact {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;

  &:hover {
    color: #ed7a20;
  }
}
