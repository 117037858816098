/*==
 *== Header caption
 *== ======================================= ==*/

.header-caption {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.3;

  &__text {
  }

  &__addr {
    font-size: 10px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
