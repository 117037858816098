/*==
 *== Card with fold angle
 *== ======================================= ==*/

.fold-card {
  position: relative;
  display: flex;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    @include z-depth(1);
    width: 100%;
    height: 100%;
    content: '';
    transform: translate(-50%, -50%);
    border-top-left-radius: 27px;
  }

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    clip-path: polygon(0 16px, 16px 0, 100% 0, 100% 100%, 0 100%);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      content: '';
      border: 8px solid transparent;
      border-color: transparent #fefefe #fefefe transparent;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
  }

  img {

  }
}
