/*==
 *== Title
 *== ======================================= ==*/

.all-title {
  margin-bottom: 20px;
  color: #930;
  font-size: 20px;
  font-weight: bold;
}
