/*==
 *== Effect 5
 *== ======================================= ==*/

.effect5 {
  position: relative;
}

.effect5:before, .effect5:after {
  position: absolute;
  z-index: -1;
  top: 80%;
  bottom: 32px;
  left: 10px;
  width: 50%;
  max-width: 190px;
  content: "";
  transform: rotate(-8deg);
  opacity: 0.55;
  background: #777;
  box-shadow: 0 35px 20px #777;
}

.effect5:after {
  right: 10px;
  left: auto;
  transform: rotate(8deg);
}
