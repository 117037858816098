/*==
 *== Header button
 *== ======================================= ==*/

.header-button {
  display: inline-block;
  padding: 20px 30px;
  user-select: none;
  transition: 0.3s;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff;
  background-color: #ed7a20;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ca6210;
  }

  @include media-breakpoint-down(md) {
    padding: 12px 16px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 10px;
    font-size: 12px;
  }
}
