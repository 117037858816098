/*==
 *== Contacts page
 *== ======================================= ==*/

.contacts-page {
  position: relative;

  &::before {
    position: absolute;
    top: -70px;
    left: 50%;
    width: 311px;
    height: 96px;
    margin-left: -420px;
    content: '';
    pointer-events: none;
    background: url('/assets/components/themebootstrap/img/bg_pen.png') no-repeat;
    background-size: contain;
  }
}
