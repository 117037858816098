/*==
 *== Readmore
 *== ======================================= ==*/

.readmore-btn {
  display: inline-block;
  padding: 3px 5px;
  vertical-align: top;
  text-transform: uppercase;
  color: #ec6920;
  border: 2px solid #ec6920;
  font-size: 13px;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    color: #fff;
    background: #ec6920;
  }

  @include media-breakpoint-down(sm) {
    font-size: 11px;
  }

  &_white {
    color: #fff;
    border-color: #fff;

    &:hover {
      color: #ec6920;
      background: #fff;
    }
  }
}
