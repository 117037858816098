/*==
 *==
 *== ======================================= ==*/

.page {
  display: block;

  &__crumbs {
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 210px;
    padding: 30px $content-gutter;
    padding: 30px var(--content-gutter);
    color: #fff;
    background: no-repeat 50% 0;
    background-size: cover;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: rgba(#476589, 1);
    }
  }

  &__body {
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }

  h1 {
    flex-grow: 1;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1;
    @include media-breakpoint-up(mb) {
      font-size: 19px;
    }
    @include media-breakpoint-up(sm) {
      flex-basis: 60%;
      font-size: 34px;
    }
  }

  &__titles {
  }

  &__desc {
    flex-basis: 40%;
    flex-grow: 1;
    margin-top: 1rem;
  }

  &__form {
    margin-top: 30px;
  }

  @include media-breakpoint-up(sm) {
    &__header {
      &::before {
        background: rgba(#000, .5);
        background: rgba(#476589, .7);
      }
    }
  }

  @include media-breakpoint-up(md){
    &__form {
      //display: flex;
      //justify-content: flex-end;

      .fast-form {
        //flex-basis: 50%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      padding-right: $content-gutter;
      padding-right: var(--content-gutter);
    }
    &__titles {
      display: flex;
      align-items: center;
    }
    &__desc {
      margin-top: 0;
    }
    &__form {
      .fast-form {
        //flex-basis: 40%;
      }
    }
  }
}

.page-content {
  padding: 20px $content-gutter 45px;
  padding: 20px var(--content-gutter) 45px;
  font-size: 16px;

  > p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.user-content {
  > p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// todo: deprecated
//.bginner {
//  @extend %deprecated;
//  min-height: 170px;
//  background: url('../img/bg_inner.jpg') no-repeat 0px -45px; background-size: cover;
//}
//
//.bgpagetitle {
//  @extend %deprecated;
//  position: relative;
//  min-height: 120px;
//  margin-top: -35px;
//  background: url('../img/bg_pagetitle.png') no-repeat;
//  background-size: cover;
//}
//
//.iangle {
//  @extend %deprecated;
//  position: absolute;
//  top: 2px;
//  left: 48%;
//  min-width: 29px;
//  min-height: 14px;
//  background: url('../img/angle.png') no-repeat;
//}
//
//.bgpagetitle h1 {
//  text-align: center;
//  text-transform: uppercase;
//  font-size: 24px;
//  font-weight: bold;
//  padding: 0;
//  margin: 0;
//  color: #4c4c4c;
//  letter-spacing: 1px;
//}
//
//.inner {
//  @extend %deprecated;
//  font-size: 16px;
//  padding: 20px $content-gutter 45px;
//}
