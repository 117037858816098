/*==
 *== Article item of list
 *== ======================================= ==*/

.article-item {
  $size: 40px;
  $icon-size: 45px;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__icon {
    position: relative;
    flex-shrink: 0;
    width: $size;
    height: $size;

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 45px;
      max-width: none;
      height: 45px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 40px;
    margin: 0 0 0 20px;
    padding: 8px 0 10px;
    color: #930;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    font-size: 18px;
    font-weight: bold;

    a {
      color: inherit;
    }
  }

  &__content {
  }

  &__footer {
    margin-top: 15px;
  }
}
