/*==
 *== Benefits
 *== ======================================= ==*/

.benefits {
  position: relative;
  padding: $section-top-padding $section-side-padding;
  background: url('/assets/components/themebootstrap/img/bg_preim.jpg') no-repeat 50% 0;
  background-size: cover;

  &__content {
    margin-top: 20px;
  }

  @include media-breakpoint-down(sm) {
    @include make-margins();
  }
}

.benefit {
  width: 100%;
  padding-top: 10px;
  //padding-bottom: 10px;

  &__icon {
    display: block;
    width: 101px;
    height: 101px;
    margin: auto;

  }

  &__title {
    display: block;
    min-height: 1.3 * 2em;
    color: #cc6701;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
  }

  &__text {
    margin-top: 0.5em;
    margin-bottom: 0 !important;
    font-size: 14px;
  }

  &_center {
    text-align: center;
  }

  @include media-breakpoint-down(lg) {
    &__title {
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(sm) {
    &__title {
      font-size: 16px;
    }
    &__text {
      font-size: 14px;
    }
  }

  @include media-breakpoint-down(mb) {
    overflow: hidden;
    &__icon {
      float: left;
      width: 50px;
      height: 50px;
    }
    &__title {
      min-height: 0;
      margin-left: 70px;

      br {
        display: none;
      }
    }
    &__text {
      margin-left: 70px;
    }
    &_center {
      text-align: left;
    }
  }
}


.getcost {
  a {
    display: block;
    width: 182px;
    max-width: 100%;
    margin: auto;
  }

  img {
    width: 100%;
    transition: all 0.5s ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }

  @include media-breakpoint-down(mb) {
    a {
      width: 90px;
    }
  }
}
