/*==
 *== Objects
 *== ======================================= ==*/

.objects {
  &__item {
    display: flex;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }
  }
}
