/*==
 *== Warranty block
 *== ======================================= ==*/

.warranty-block {
  $bg: #feb604;
  position: relative;

  display: flex;
  align-items: center;

  padding-top: 5px + 20px;
  padding-right: $content-gutter;
  padding-right: var(--content-gutter);
  padding-bottom: 5px + 20px;
  padding-left: $content-gutter;
  padding-left: var(--content-gutter);
  background: rgba($bg, .1);

  &::before,
  &::after {
    position: absolute;
    right: 0;
    left: 0;
    height: 5px;
    content: '';
    pointer-events: none;
    @include dd-strip-gradient(#fae1a8);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &__icon {
    flex-shrink: 0;
    padding-right: $content-gutter;
    padding-right: var(--content-gutter);
  }

  &__img {
    width: auto;
    height: 60px;
    vertical-align: top;
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    text-transform: uppercase;
    color: $color-primary-text;
    font-size: 30px;
    font-weight: bold;
    line-height: (26/30);
  }

  &__text {
    font-size: 18px;
    line-height: (26/18);
  }

  @include media-breakpoint-down(sm) {
    display: block;
    &__content {
      margin: 10px 0;
    }
  }
}
