/*==
 *== Activity card
 *== ======================================= ==*/

.activity-card {
  $offset-top: 60px;

  background: #fff no-repeat 50% $offset-top;
  background-size: cover;
  box-shadow: 2px 0 4px -3px rgba(50, 50, 50, 0.75);

  > a {
    display: block;
    width: 100%;
    height: 0;
    padding-top: $offset-top;
    padding-bottom: percentage(145/165);

    &:hover {
      text-decoration: none;
      color: #fa8b1f;
    }

    > span {
      position: relative;
      top: ($offset-top / -1);
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 90px;
      margin: 0 auto;
      padding: 5px 5px 0;
      text-align: center;
      text-transform: uppercase;
      border-radius: 0 0 106px 106px / 0 0 25px 25px;
      background: rgba(white, 1);
      font-size: 12px;
      font-weight: bold;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        content: "";
        background: url('/assets/components/themebootstrap/img/poloski_red.png') repeat-x;
      }
    }
  }

  @include media-breakpoint-up(mb) {
    > a {
      > span {
        padding-right: 10px;
        padding-left: 10px;
        font-size: 13px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    > a {
      > span {
        font-size: 14px;
      }
    }
  }

  @include media-breakpoint-only(lg) {
    > a {
      > span {
        font-size: 12px;
      }
    }
  }
}
