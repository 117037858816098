/*==
 *== Additional styles
 *== ======================================= ==*/

//// todo: @deprecated
//.newsfotobig {
//  @extend %deprecated;
//  padding: 20px;
//}
//
//.newsfotobig img {
//  padding: 5px;
//  border: 1px solid #ddd;
//  border-radius: 5px;
//}
//
//.newsfotobig img:hover {
//  background: orange;
//  border: 1px solid orange;
//}
//
///* Объекты */
//// todo: @deprecated
//.objectitem {
//  @extend %deprecated;
//  position: relative;
//  background: #fdebcd;
//  border: 1px solid orange;
//  border-radius: 5px;
//  padding: 12px;
//  min-height: 395px;
//  margin-bottom: 20px;
//}
//
//.objectitem img {
//  margin: 0 auto;
//  border: 1px solid orange;
//}
//
//.objectitem span {
//  display: block;
//}
//
//.objectitem a {
//  display: block;
//  color: #c96651;
//  text-decoration: underline;
//  padding: 3px 0;
//  font-size: 15px;
//  font-weight: bold;
//}
//
//.objectopis {
//  @extend %deprecated;
//  width: 100%;
//  min-height: 50px;
//  background: transparent;
//  padding: 5px;
//}
//
//.obfotolist {
//  @extend %deprecated;
//  margin: 35px 0;
//}
//
//.obfotolist img {
//  float: left;
//  margin: 10px;
//}

.icones {
  float: left;
}

.icones img {
  width: auto;
  height: 27px;
}

.icones:first-child {
  padding-right: 6px;
}


#fixbar {
  position: fixed;
  z-index: 99999;
  left: 50%;
  margin-left: -585px;
}

@include media-breakpoint-down(lg) {
  #fixbar {
    position: fixed;
    z-index: 99999;
    left: 50%;
    margin-left: -470px;
  }
}

@media screen and (max-width: 1010px) {
  #fixbar {
    position: fixed;
    z-index: 99999;
    left: 50%;
    margin-left: -380px;
  }
}

@include media-breakpoint-down(sm) {
  #fixbar {
    position: fixed;
    z-index: 99999;
    left: 50%;
    margin-left: -364px;
  }
}

.tablesorter-blue {
  @extend %deprecated;
}

//.tablesorter-blue .tablesorter-filter-row td {
//  background-color: #fffacd;
//}
//
//.tablesorter-blue input.tablesorter-filter:hover, .tablesorter-blue select.tablesorter-filter:hover {
//  background-color: #fff;
//}
//
//.tablesorter-blue .tablesorter-filter-row .disabled {
//  opacity: 0.5;
//  filter: alpha(opacity=50);
//  cursor: not-allowed;
//  border: 0;
//}
