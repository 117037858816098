/*==
 *== News page
 *== ======================================= ==*/

.news-list {

  &__item {
    & + & {
      margin-top: 30px;
    }
  }
}
