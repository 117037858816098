/*==
 *== Typical project card
 *== ======================================= ==*/

.tp-card {
  &__main {
    @include make-row();
    margin-bottom: 2rem;
  }

  &__photo {
    @include make-col-ready();
    position: relative;
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    border: 5px solid white;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #ddd;
  }

  &__info {
    @include make-col-ready();
  }

  &__description {
    > :first-child {
      margin-top: 0 !important;
    }
  }

  &__shop {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &__price {
    font-size: 20px;

    span {
      color: $color-primary-text;
      font-size: 30px;
    }
  }

  &__price,
  &__action {
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(md) {
    &__photo {
      @include make-col(4);
    }
    &__info {
      @include make-col(8);
    }
  }
}
