/*==
 *== WebFonts
 *== ======================================= ==*/

//noinspection CssUnknownTarget
@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Regular.eot');
  src: local('***'),
  url('../fonts/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PTSans-Regular.woff') format('woff'),
  url('../fonts/PTSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//noinspection CssUnknownTarget
@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Bold.eot');
  src: local('***'),
  url('../fonts/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PTSans-Bold.woff') format('woff'),
  url('../fonts/PTSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
