/*==
 *== Principles
 *== ======================================= ==*/

.principles {
  position: relative;
  padding: $section-top-padding $section-side-padding;
  background: #fbac20 url('/assets/components/themebootstrap/img/bg-project.jpg') no-repeat 50% 0;
  background-size: cover;

  &__decoration-line {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @include media-breakpoint-down(sm) {
    @include make-margins();
  }

  .row {
    justify-content: center;
  }
}

.principle {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 3px solid #fff;
  font-size: 15px;
  line-height: 120%;

  p:last-child {
    margin-bottom: 0;
  }
}
