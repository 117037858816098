/*==
 *== Reviews
 *== ======================================= ==*/

.reviews {
  &:not(:first-child) {
    margin-top: 30px;
  }

  &__item {
    &:nth-child(n + 2) {
      margin-top: 30px;
    }
  }
}
