/*==
 *== Stages of order
 *== ======================================= ==*/

.stages {
  position: relative;
  margin: (10px) ($content-gutter / -1);
  margin: (10px) calc(var(--content-gutter) / -1);
  padding: 30px $content-gutter;
  padding: 30px var(--content-gutter);
  background: #eef0f6 url("../img/stages-bg.jpg") center;
  background-size: cover;

  &::before,
  &::after {
    @include dd-strip-gradient(#e0e7f0);
    position: absolute;
    right: 0;
    left: 0;
    height: 5px;
    content: '';
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &__heading {

  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px;
  }

  &__item {
    //margin-right: 20px;
    flex-shrink: 0;
    margin-top: 30px;

    //&:last-child { margin-right: 0; }
  }
}

.stage {
  $size: 72px;
  position: relative;
  width: 130px;
  //height: $size;

  &::before {
    position: absolute;
    width: $size;
    height: $size;
    content: '';
    border-radius: 100%;
    background: #fff;
  }

  &__index {
    position: absolute;
    top: -12px;
    left: 0;
    width: $size;
    text-align: center;
    color: #fc9f12;
    font-family: "PT Sans", sans-serif;
    font-size: 60px;
    font-weight: bold;
    line-height: 0.5;
  }

  &__title {
    position: relative;
    width: 130px;
    padding-top: ($size / 2);
    font-size: 16px;
    line-height: (21/16);
  }
}
