@charset "UTF-8";
/*==
 *== WebFonts
 *== ======================================= ==*/
@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/PTSans-Regular.eot");
  src: local("***"), url("../fonts/PTSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PTSans-Regular.woff") format("woff"), url("../fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/PTSans-Bold.eot");
  src: local("***"), url("../fonts/PTSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/PTSans-Bold.woff") format("woff"), url("../fonts/PTSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.deprecated, .poloski, .owl-carousel:not(.carousel-6x) .item, .subhead, .tablesorter-blue, .photo-gallery {
  outline: 3px dotted red !important;
  outline-offset: -3px !important; }

:root {
  --content-gutter: 0; }
  @media (min-width: 576px) {
    :root {
      --content-gutter: 20px; } }
  @media (min-width: 992px) {
    :root {
      --content-gutter: 35px; } }

/*==
 *== Striped line
 *== ======================================= ==*/
.strip-line {
  z-index: 9999;
  width: 100%;
  height: 5px;
  pointer-events: none;
  opacity: 0.75;
  background: url("/assets/components/themebootstrap/img/poloski.png") repeat-x; }

.poloski {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 5px;
  opacity: 0.75;
  background: url("/assets/components/themebootstrap/img/poloski.png") repeat-x; }

/*==
 *==
 *== ======================================= ==*/
.page {
  display: block; }
  .page__header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 210px;
    padding: 30px 35px;
    padding: 30px var(--content-gutter);
    color: #fff;
    background: no-repeat 50% 0;
    background-size: cover; }
    .page__header::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: #476589; }
  .page__body {
    position: relative;
    padding-right: 20px;
    padding-left: 20px; }
    @media (min-width: 576px) {
      .page__body {
        padding: 0; } }
  .page h1 {
    flex-grow: 1;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1; }
    @media (min-width: 375px) {
      .page h1 {
        font-size: 19px; } }
    @media (min-width: 576px) {
      .page h1 {
        flex-basis: 60%;
        font-size: 34px; } }
  .page__desc {
    flex-basis: 40%;
    flex-grow: 1;
    margin-top: 1rem; }
  .page__form {
    margin-top: 30px; }
  @media (min-width: 576px) {
    .page__header::before {
      background: rgba(0, 0, 0, 0.5);
      background: rgba(71, 101, 137, 0.7); } }
  @media (min-width: 992px) {
    .page h1 {
      padding-right: 35px;
      padding-right: var(--content-gutter); }
    .page__titles {
      display: flex;
      align-items: center; }
    .page__desc {
      margin-top: 0; } }

.page-content {
  padding: 20px 35px 45px;
  padding: 20px var(--content-gutter) 45px;
  font-size: 16px; }
  .page-content > p:last-child {
    margin-bottom: 0; }

.user-content > p:last-child {
  margin-bottom: 0; }

/*==
 *== Top banner on home page
 *== ======================================= ==*/
.topbanner {
  position: relative;
  height: auto;
  background: #4c4c4c no-repeat 50% 50%;
  background-size: contain;
  margin-right: -10px;
  margin-left: -10px; }
  @media (min-width: 992px) {
    .topbanner {
      margin-right: -15px;
      margin-left: -15px; } }
  .topbanner__mock {
    display: block;
    width: 100%;
    height: auto;
    min-height: 200px;
    opacity: 0.7; }
  .topbanner__positioner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .topbanner__content {
    max-width: 70%;
    padding: 10px 10px 10px 15px;
    color: #fff;
    border-left: 4px solid #f78e2a;
    background: rgba(0, 0, 0, 0.7);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5); }
  .topbanner__title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1; }
  .topbanner__subtitle {
    text-transform: lowercase;
    font-size: 10px; }
  @media (min-width: 375px) {
    .topbanner__title {
      font-size: 19px; } }
  @media (min-width: 576px) {
    .topbanner__content {
      max-width: 100%;
      background: rgba(0, 0, 0, 0.5); }
    .topbanner__title {
      font-size: 34px; }
    .topbanner__subtitle {
      font-size: 15px;
      font-weight: bold; } }
  @media (min-width: 768px) {
    .topbanner {
      margin: 0; } }

/*==
 *== Effect 5
 *== ======================================= ==*/
.effect5 {
  position: relative; }

.effect5:before, .effect5:after {
  position: absolute;
  z-index: -1;
  top: 80%;
  bottom: 32px;
  left: 10px;
  width: 50%;
  max-width: 190px;
  content: "";
  transform: rotate(-8deg);
  opacity: 0.55;
  background: #777;
  box-shadow: 0 35px 20px #777; }

.effect5:after {
  right: 10px;
  left: auto;
  transform: rotate(8deg); }

/*==
 *== Owl carousel
 *== ======================================= ==*/
.carousel-6x {
  padding: 0 20px; }
  .carousel-6x .owl-stage {
    display: flex; }
  .carousel-6x .owl-item {
    display: flex; }
    .carousel-6x .owl-item > * {
      flex-grow: 1; }
  .carousel-6x .owl-nav {
    color: currentColor; }
  .carousel-6x .owl-prev,
  .carousel-6x .owl-next {
    position: absolute;
    top: 85px;
    width: 15px;
    height: 30px;
    text-indent: -9999px;
    font-size: 0; }
    .carousel-6x .owl-prev::before,
    .carousel-6x .owl-next::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      content: '';
      border-width: 15px;
      border-color: transparent currentColor;
      border-style: solid; }
  .carousel-6x .owl-prev {
    left: 0; }
    .carousel-6x .owl-prev::before {
      border-left-width: 0; }
  .carousel-6x .owl-next {
    right: 0; }
    .carousel-6x .owl-next::before {
      border-right-width: 0; }

.owl-carousel:not(.carousel-6x) {
  padding: 0 20px; }
  .owl-carousel:not(.carousel-6x) .owl-nav .owl-prev {
    position: absolute;
    top: 85px;
    left: 0;
    width: 15px;
    height: 30px;
    background: url("/assets/components/themebootstrap/img/sl_prev.png") no-repeat;
    font-size: 0px; }
  .owl-carousel:not(.carousel-6x) .owl-nav .owl-next {
    position: absolute;
    top: 85px;
    right: 0;
    width: 15px;
    height: 30px;
    background: url("/assets/components/themebootstrap/img/sl_next.png") no-repeat;
    font-size: 0px; }
  .owl-carousel:not(.carousel-6x) .item {
    padding: 0px 5px; }
  .owl-carousel:not(.carousel-6x) .item h4 {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 14px; }
  .owl-carousel:not(.carousel-6x) .item h4 span {
    margin-bottom: 5px;
    color: #d3802d;
    font-size: 22px;
    font-weight: bold; }
  .owl-carousel:not(.carousel-6x) .item a {
    position: relative;
    left: -3px;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;
    text-transform: none;
    color: #d3802d;
    border: 0;
    font-size: 16px; }
  .owl-carousel:not(.carousel-6x) .item p {
    margin: 0;
    padding: 0;
    padding-top: 7px;
    text-align: left;
    text-transform: none;
    color: #333;
    border: 0;
    font-size: 15px; }

.owl-nav .disabled {
  opacity: 0.5; }

/*==
 *== Activity buildings
 *== ======================================= ==*/
.activity-buildings {
  position: relative;
  z-index: 1;
  padding-top: 30px;
  padding-bottom: 15px; }
  .activity-buildings__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px; }
    @media (min-width: 992px) {
      .activity-buildings__list {
        margin-right: -15px;
        margin-left: -15px; } }
  .activity-buildings__item {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 50%;
    max-width: 50%;
    margin-top: 30px; }
    @media (min-width: 992px) {
      .activity-buildings__item {
        padding-right: 15px;
        padding-left: 15px; } }
  @media (max-width: 767px) {
    .activity-buildings {
      margin-right: -10px;
      margin-left: -10px;
      padding-right: 10px;
      padding-left: 10px;
      margin-top: -15px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .activity-buildings {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .activity-buildings {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 576px) {
    .activity-buildings__item {
      flex-basis: 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .activity-buildings__item {
      flex-basis: 16.66667%;
      max-width: 16.66667%; } }

/*==
 *== Activity card
 *== ======================================= ==*/
.activity-card {
  background: #fff no-repeat 50% 60px;
  background-size: cover;
  box-shadow: 2px 0 4px -3px rgba(50, 50, 50, 0.75); }
  .activity-card > a {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 60px;
    padding-bottom: 87.87879%; }
    .activity-card > a:hover {
      text-decoration: none;
      color: #fa8b1f; }
    .activity-card > a > span {
      position: relative;
      top: -60px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 90px;
      margin: 0 auto;
      padding: 5px 5px 0;
      text-align: center;
      text-transform: uppercase;
      border-radius: 0 0 106px 106px / 0 0 25px 25px;
      background: white;
      font-size: 12px;
      font-weight: bold; }
      .activity-card > a > span::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        content: "";
        background: url("/assets/components/themebootstrap/img/poloski_red.png") repeat-x; }
  @media (min-width: 375px) {
    .activity-card > a > span {
      padding-right: 10px;
      padding-left: 10px;
      font-size: 13px; } }
  @media (min-width: 576px) {
    .activity-card > a > span {
      font-size: 14px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .activity-card > a > span {
      font-size: 12px; } }

/*==
 *== Card with fold angle
 *== ======================================= ==*/
.fold-card {
  position: relative;
  display: flex; }
  .fold-card::before {
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px, rgba(0, 0, 0, 0.117647) 0 1px 4px;
    width: 100%;
    height: 100%;
    content: '';
    transform: translate(-50%, -50%);
    border-top-left-radius: 27px; }
  .fold-card__container {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    clip-path: polygon(0 16px, 16px 0, 100% 0, 100% 100%, 0 100%); }
    .fold-card__container::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      content: '';
      border: 8px solid transparent;
      border-color: transparent #fefefe #fefefe transparent;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); }

/*==
 *== Typical projects
 *== ======================================= ==*/
.typical-block {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  background: url("/assets/components/themebootstrap/img/section-divider.png") no-repeat 50% 0, url("/assets/components/themebootstrap/img/typical-bg-top-left.gif") no-repeat 0 0, url("/assets/components/themebootstrap/img/typical-bg-bottom-right.gif") no-repeat 100% 100%, white; }
  .typical-block__header {
    margin-bottom: 30px; }
  .typical-block__content {
    padding: 0 20px; }
    @media (max-width: 374px) {
      .typical-block__content {
        padding: 0; } }

.typical {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .typical__item {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    flex-basis: 50%;
    max-width: 50%;
    display: flex; }
    .typical__item > * {
      width: 100%; }
  @media (max-width: 374px) {
    .typical__item:nth-child(n + 3) {
      margin-top: 10px; } }
  @media (min-width: 375px) {
    .typical {
      display: flex;
      flex-wrap: wrap;
      margin-right: -11px;
      margin-left: -11px; }
      .typical__item {
        position: relative;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 11px;
        padding-left: 11px;
        flex-basis: 50%;
        max-width: 50%; } }
  @media (min-width: 375px) and (max-width: 575px) {
    .typical__item:nth-child(n + 3) {
      margin-top: 22px; } }
  @media (min-width: 576px) {
    .typical__item {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
    .typical__item:nth-child(n + 4) {
      margin-top: 22px; } }
  @media (min-width: 1200px) {
    .typical__item {
      flex-basis: 16.66667%;
      max-width: 16.66667%; }
    .typical__item:nth-child(n + 4) {
      margin-top: 0; }
    .typical__item:nth-child(n + 7) {
      margin-top: 22px; } }

.typical-project {
  position: relative; }
  .typical-project, .typical-project a {
    text-decoration: none !important; }
  .typical-project::after {
    position: absolute;
    right: -1px;
    bottom: -1px;
    left: -1px;
    height: 5px;
    content: '';
    background-image: linear-gradient(135deg, #d2d7e2 35%, transparent 35%, transparent 50%, #d2d7e2 50%, #d2d7e2 85%, transparent 85%, transparent);
    background-position: 50% 0;
    background-size: 16px 16px; }
  .typical-project__cover {
    display: block;
    max-width: 100%;
    height: 110px;
    margin: auto;
    padding: 14px;
    object-fit: contain; }
  .typical-project__title {
    padding: 5px 10px;
    text-transform: uppercase;
    color: #d37b23;
    border-top: 3px solid transparent;
    font-size: 13px;
    font-weight: bold; }
  .typical-project__desc {
    padding: 5px 10px;
    color: #000; }
  .typical-project:hover .typical-project__title {
    border-top-color: #ff7f00; }
  .typical-project:hover .typical-project__desc {
    color: #ff7f00; }
  @media (min-width: 576px) {
    .typical-project__title {
      font-size: 16px; } }

/*==
 *== Factory promo
 *== ======================================= ==*/
.factory-promo {
  position: relative;
  padding-top: 45px;
  padding-bottom: 30px;
  background: url("/assets/components/themebootstrap/img/section-divider.png") no-repeat 50% 0, url("/assets/components/themebootstrap/img/bg-top-left-promo.png") no-repeat 0 0, white; }
  .factory-promo::before {
    position: absolute;
    top: -45px;
    left: 30px;
    width: 310px;
    height: 96px;
    content: '';
    pointer-events: none;
    background: url("/assets/components/themebootstrap/img/pen.png"); }
  .factory-promo > * {
    position: relative; }
  .factory-promo__content {
    margin-top: 35px; }
  .factory-promo__container {
    padding: 0 20px; }
  @media (max-width: 767px) {
    .factory-promo {
      margin-right: -10px;
      margin-left: -10px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .factory-promo {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (max-width: 767px) {
      .factory-promo::before {
        top: -33.75px;
        width: 232.5px;
        height: 72px;
        background-size: 100% 100%; } }
  @media (max-width: 575px) {
    .factory-promo::before {
      top: -18px;
      left: 0;
      width: 124px;
      height: 38.4px; } }

/*==
 *== Principles
 *== ======================================= ==*/
.principles {
  position: relative;
  padding: 30px 20px;
  background: #fbac20 url("/assets/components/themebootstrap/img/bg-project.jpg") no-repeat 50% 0;
  background-size: cover; }
  .principles__decoration-line {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0; }
  @media (max-width: 767px) {
    .principles {
      margin-right: -10px;
      margin-left: -10px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .principles {
      margin-right: -15px;
      margin-left: -15px; } }
  .principles .row {
    justify-content: center; }

.principle {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 3px solid #fff;
  font-size: 15px;
  line-height: 120%; }
  .principle p:last-child {
    margin-bottom: 0; }

/*==
 *== Benefits
 *== ======================================= ==*/
.benefits {
  position: relative;
  padding: 30px 20px;
  background: url("/assets/components/themebootstrap/img/bg_preim.jpg") no-repeat 50% 0;
  background-size: cover; }
  .benefits__content {
    margin-top: 20px; }
  @media (max-width: 767px) {
    .benefits {
      margin-right: -10px;
      margin-left: -10px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .benefits {
      margin-right: -15px;
      margin-left: -15px; } }

.benefit {
  width: 100%;
  padding-top: 10px; }
  .benefit__icon {
    display: block;
    width: 101px;
    height: 101px;
    margin: auto; }
  .benefit__title {
    display: block;
    min-height: 2.6em;
    color: #cc6701;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3; }
  .benefit__text {
    margin-top: 0.5em;
    margin-bottom: 0 !important;
    font-size: 14px; }
  .benefit_center {
    text-align: center; }
  @media (max-width: 1199px) {
    .benefit__title {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .benefit__title {
      font-size: 16px; }
    .benefit__text {
      font-size: 14px; } }
  @media (max-width: 575px) {
    .benefit {
      overflow: hidden; }
      .benefit__icon {
        float: left;
        width: 50px;
        height: 50px; }
      .benefit__title {
        min-height: 0;
        margin-left: 70px; }
        .benefit__title br {
          display: none; }
      .benefit__text {
        margin-left: 70px; }
      .benefit_center {
        text-align: left; } }

.getcost a {
  display: block;
  width: 182px;
  max-width: 100%;
  margin: auto; }

.getcost img {
  width: 100%;
  transition: all 0.5s ease-in-out; }

.getcost:hover img {
  transform: scale(1.1); }

@media (max-width: 575px) {
  .getcost a {
    width: 90px; } }

/*==
 *== Projects
 *== ======================================= ==*/
.projects {
  position: relative;
  padding: 30px 0;
  background: url("/assets/components/themebootstrap/img/bg_project.jpg") no-repeat 50% 0;
  background-size: cover; }
  .projects__container {
    padding: 0 10px; }
  .projects__content {
    margin-top: 20px; }
  .projects__decoration-line {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0; }
  .projects .owl-nav {
    color: #fff; }
  @media (max-width: 767px) {
    .projects {
      margin-right: -10px;
      margin-left: -10px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .projects {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 768px) {
    .projects__container {
      padding: 0 20px; } }

.project-slide {
  margin: 0 7px;
  padding: 0;
  background-color: #fff;
  box-shadow: 3px 0 5px -3px rgba(50, 50, 50, 0.75), -3px 0 5px -3px rgba(50, 50, 50, 0.75); }
  .project-slide__cover {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 74.35897%; }
    .project-slide__cover img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .project-slide__cover::after {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -10px;
      content: '';
      pointer-events: none;
      border: 10px solid transparent;
      border-top-color: #fc8219; }
  .project-slide__title {
    margin-top: 10px;
    padding: 10px;
    color: #d3802d;
    font-size: 14px;
    line-height: 120%; }
    .project-slide__title a {
      display: block;
      text-decoration: none;
      color: inherit; }
      .project-slide__title a:hover {
        text-decoration: underline; }

/*==
 *== Prices and form
 *== ======================================= ==*/
.prices-and-form {
  position: relative;
  padding: 0 0 30px;
  background: url("/assets/components/themebootstrap/img/stoimost.png") no-repeat 0 0, url("/assets/components/themebootstrap/img/bg_forma.png") no-repeat right 0 top -5px, #fff; }
  .prices-and-form .heading {
    margin-bottom: 22px; }
  .prices-and-form__container {
    padding-right: 20px;
    padding-left: 40px; }
  .prices-and-form__cost, .prices-and-form__form {
    padding-top: 30px; }
  .prices-and-form .table-cost {
    box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.3); }
  @media (max-width: 991px) {
    .prices-and-form__form {
      background: #fff; } }
  @media (max-width: 767px) {
    .prices-and-form {
      margin-right: -10px;
      margin-left: -10px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .prices-and-form {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (max-width: 767px) {
      .prices-and-form__container {
        padding-left: 20px; } }

/*==
 *== Table cost
 *== ======================================= ==*/
.tablecost,
.table-cost {
  background: #fff; }
  .tablecost thead,
  .table-cost thead {
    color: #fff;
    background: rgba(93, 112, 144, 0.65) url("/assets/components/themebootstrap/img/bg_htable.png") repeat-x 0 -1px;
    font-size: 16px; }
  .tablecost th,
  .table-cost th {
    padding: 11px;
    font-weight: normal; }
  .tablecost > thead > tr > th,
  .tablecost > thead > tr > td,
  .tablecost > tbody > tr > th,
  .tablecost > tbody > tr > td,
  .table-cost > thead > tr > th,
  .table-cost > thead > tr > td,
  .table-cost > tbody > tr > th,
  .table-cost > tbody > tr > td {
    vertical-align: middle; }
    .tablecost > thead > tr > th:first-child,
    .tablecost > thead > tr > td:first-child,
    .tablecost > tbody > tr > th:first-child,
    .tablecost > tbody > tr > td:first-child,
    .table-cost > thead > tr > th:first-child,
    .table-cost > thead > tr > td:first-child,
    .table-cost > tbody > tr > th:first-child,
    .table-cost > tbody > tr > td:first-child {
      padding: 11px;
      padding-left: 30px; }
  .tablecost span,
  .table-cost span {
    color: #d78635;
    font-size: 15px;
    font-weight: bold; }

/*==
 *== Order form
 *== ======================================= ==*/
.form-order-ext__toggle {
  text-align: center;
  font-size: 15px;
  font-weight: bold; }
  .form-order-ext__toggle a {
    text-decoration: underline;
    color: #c60; }

.form-order-ext__params {
  display: none; }

.form-order-ext textarea {
  height: 70px; }

.bgforma {
  min-height: 400px;
  padding: 0 20px;
  background: url("/assets/components/themebootstrap/img/bg_forma.png") no-repeat;
  background-position: 8px 0; }
  .bgforma input {
    margin-top: 12px; }
  .bgforma input::-webkit-input-placeholder {
    color: #333; }
  .bgforma input::-moz-placeholder {
    color: #333; }
  .bgforma input:-moz-placeholder {
    color: #333; }
  .bgforma input:-ms-input-placeholder {
    color: #333; }
  .bgforma textarea::-webkit-input-placeholder {
    color: #333; }
  .bgforma textarea::-moz-placeholder {
    color: #333; }
  .bgforma textarea:-moz-placeholder {
    color: #333; }
  .bgforma textarea:-ms-input-placeholder {
    color: #333; }
  .bgforma input[type="submit"] {
    width: 170px;
    height: 45px;
    margin: 0;
    margin-top: 15px;
    padding: 0;
    border: 0;
    background: url("/assets/components/themebootstrap/img/bg_button.png") no-repeat; }
  .bgforma input[type="submit"]:active {
    margin-left: 2px;
    border: 0 !important;
    background-position: 2px 2px; }
  .bgforma input[type="submit"]:focus {
    border: 0 !important; }
  .bgforma p {
    padding-top: 5px;
    padding-left: 3px;
    font-size: 14px; }
  @media (max-width: 767px) {
    .bgforma {
      width: 60%;
      margin: 0 auto;
      background: #fff; } }

.upload {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #ddd; }
  .upload div {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    text-transform: uppercase;
    color: #fa8b1f;
    border: 2px solid #fa8b1f;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold; }
    .upload div:active {
      margin-top: 1px;
      margin-left: 1px; }

.moreparam {
  text-align: center; }
  .moreparam a {
    text-decoration: underline;
    color: #c60;
    font-size: 15px;
    font-weight: bold; }

/*==
 *== Custom buttons
 *== ======================================= ==*/
.btn-styled {
  position: relative;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 40px;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: uppercase;
  color: #000;
  border: none;
  border-bottom: 3px solid #d38524;
  border-radius: 5px;
  background: #e58032 radial-gradient(circle, #fcbf1a, #f89926);
  font-size: 15px;
  font-weight: bold; }
  .btn-styled::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    content: '';
    transform: rotate(45deg);
    border: 10px solid rgba(255, 255, 255, 0.2); }
  .btn-styled::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-right: -25px;
    content: '';
    transform: rotate(45deg);
    border: 10px solid rgba(255, 255, 255, 0.2); }
  .btn-styled span {
    position: relative;
    z-index: 1; }
  .btn-styled:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(229, 128, 50, 0.3); }
  .btn-styled:active {
    padding-bottom: 13px;
    border-bottom-width: 0; }
  .btn-styled:hover {
    color: #000; }

.btn-readmore {
  padding: 1px 10px 3px 10px;
  border: 2px solid orange; }

/*==
 *== Text content
 *== ======================================= ==*/
.text-content {
  position: relative;
  padding: 30px 0;
  background: url("/assets/components/themebootstrap/img/bg_proizvod.jpg") no-repeat 50% 0;
  background-size: cover; }
  .text-content::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -14px;
    content: '';
    pointer-events: none;
    border: 14px solid transparent;
    border-top-color: #fc8019; }
  .text-content__container {
    padding: 0 20px; }
  .text-content__main {
    margin-top: 30px;
    font-size: 15px;
    column-width: 400px;
    column-count: 2;
    column-gap: 50px; }
  @media (max-width: 767px) {
    .text-content {
      margin-right: -10px;
      margin-left: -10px; } }
  @media (max-width: 767px) and (min-width: 992px) {
    .text-content {
      margin-right: -15px;
      margin-left: -15px; } }

/*==
 *==
 *== ======================================= ==*/
.breadcrumb {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 8px 0;
  list-style: none;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold; }
  .breadcrumb > li {
    display: inline-block; }
  .breadcrumb > li + li:before {
    padding: 0 15px;
    content: "/";
    color: #4c4c4c; }
  .breadcrumb > .active {
    color: #4c4c4c; }

/*==
 *== News carousel item
 *== ======================================= ==*/
.news-slide {
  padding: 0 5px; }
  .news-slide__date {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 14px; }
  .news-slide__day {
    margin-bottom: 5px;
    color: #d3802d;
    font-size: 22px;
    font-weight: bold; }
  .news-slide__title {
    overflow: hidden;
    height: 5.5em;
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: none;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.375; }
    .news-slide__title a {
      display: inline-block;
      text-decoration: underline;
      color: #d3802d; }
  .news-slide__content {
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: none;
    color: #333;
    border: 0;
    font-size: 15px;
    font-weight: bold; }

/*==
 *== News carousel
 *== ======================================= ==*/
.news-carousel {
  position: relative;
  min-height: 331px;
  padding-top: 30px; }
  .news-carousel::before {
    position: absolute;
    top: -63px;
    left: 0;
    width: 100%;
    height: 394px;
    content: '';
    pointer-events: none;
    background: url("/assets/components/themebootstrap/img/bg_novosti.png") no-repeat 50% 0;
    background-size: 100% auto; }
  .news-carousel__header {
    position: relative;
    margin-bottom: 20px; }
  .news-carousel__carousel {
    position: relative; }

/*==
 *== Readmore
 *== ======================================= ==*/
.readmore-btn {
  display: inline-block;
  padding: 3px 5px;
  vertical-align: top;
  text-transform: uppercase;
  color: #ec6920;
  border: 2px solid #ec6920;
  font-size: 13px;
  font-weight: bold; }
  .readmore-btn:hover {
    text-decoration: none;
    color: #fff;
    background: #ec6920; }
  @media (max-width: 767px) {
    .readmore-btn {
      font-size: 11px; } }
  .readmore-btn_white {
    color: #fff;
    border-color: #fff; }
    .readmore-btn_white:hover {
      color: #ec6920;
      background: #fff; }

/*==
 *== block heading
 *== ======================================= ==*/
.heading {
  font-size: 18px; }
  .heading__content {
    display: block;
    flex-shrink: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold; }
    .heading__content h1, .heading__content h2 {
      margin: 0; }
    .heading__content h1 {
      font-size: 22px; }
    .heading__content h2 {
      text-transform: none;
      font-size: 30px; }
    .heading__content span {
      display: block;
      text-transform: uppercase;
      color: #555;
      font-size: 15px;
      font-weight: bold; }
  .heading__side {
    display: block;
    flex-shrink: 0;
    margin-top: 10px;
    text-align: center; }
  @media (min-width: 375px) {
    .heading__content h1 {
      font-size: 26px; } }
  @media (min-width: 576px) {
    .heading {
      display: flex; }
      .heading__side {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: flex-end;
        margin-top: 0;
        transform: translateX(-100%); }
      .heading__content {
        padding-right: 110px; }
        .heading__content h1 {
          text-align: left;
          font-size: 36px; } }
  @media (min-width: 768px) {
    .heading {
      font-size: 22px; }
      .heading__content {
        font-size: 22px; } }
  @media (min-width: 1200px) {
    .heading__content {
      padding: 0 110px; }
      .heading__content h1 {
        text-align: center; } }

.subhead {
  width: 100%;
  text-align: center; }

.subhead span {
  display: inline-block;
  margin-top: 27px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold; }

.subhead p {
  display: block;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: bold; }

.subhead a {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 25px;
  margin-top: 27px;
  padding: 3px 5px;
  text-transform: uppercase;
  color: #ec6920;
  border: 2px solid #ec6920;
  font-size: 13px;
  font-weight: bold; }

/*	660	*/
@media screen and (max-width: 660px) {
  .subhead {
    width: 100%;
    text-align: center; }
  .subhead span {
    display: inline-block;
    margin-top: 27px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold; }
  .subhead p {
    display: block;
    text-transform: uppercase;
    color: #555;
    font-size: 13px;
    font-weight: bold; }
  .subhead a {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 2px;
    margin-top: 27px;
    padding: 3px 5px;
    text-transform: uppercase;
    color: #ec6920;
    border: 2px solid #ec6920;
    font-size: 11px;
    font-weight: bold; } }

@media (max-width: 767px) {
  .longtit {
    text-align: left; }
  .longtit span {
    margin-top: 27px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold; }
  .longtit p {
    font-size: 13px;
    font-weight: bold; }
  .longtit a {
    position: absolute;
    top: -10px;
    right: 25px;
    margin-top: 27px;
    padding: 3px 5px;
    text-transform: uppercase;
    color: #ec6920;
    border: 2px solid #ec6920;
    font-size: 11px;
    font-weight: bold; } }

@media screen and (max-width: 660px) {
  .longtit {
    text-align: left; }
  .longtit span {
    margin-top: 27px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold; }
  .longtit p {
    font-size: 13px;
    font-weight: bold; }
  .longtit a {
    position: absolute;
    top: -10px;
    right: 25px;
    margin-top: 27px;
    padding: 3px 5px;
    text-transform: uppercase;
    color: #ec6920;
    border: 2px solid #ec6920;
    font-size: 11px;
    font-weight: bold; } }

@media screen and (max-width: 560px) {
  .longtit {
    text-align: left; }
  .longtit span {
    margin-top: 17px;
    padding-left: 20px;
    font-size: 14px;
    font-weight: bold; }
  .longtit p {
    font-size: 12px;
    font-weight: bold; } }

.fast-form__title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold; }

.fast-form__main {
  margin-top: 15px; }

.fast-form .field-accept {
  margin-top: -20px; }

.fast-form .upload {
  margin: 0; }
  .fast-form .upload div {
    top: -1px;
    right: -1px; }

@media (min-width: 768px) {
  .fast-form .form-group {
    margin: 0 !important; }
  .fast-form .field-accept {
    margin-top: 0; }
  .fast-form__main {
    display: grid;
    grid-gap: 20px;
    grid-template-areas: 'name phone' 'email file' 'submit accept'; }
  .fast-form .field-name {
    grid-area: name; }
  .fast-form .field-phone {
    grid-area: phone; }
  .fast-form .field-email {
    grid-area: email; }
  .fast-form .field-file {
    grid-area: file; }
  .fast-form .field-submit {
    grid-area: submit; }
  .fast-form .field-accept {
    align-self: center;
    grid-area: accept; } }

@media (min-width: 992px) {
  .fast-form__main {
    grid-template-areas: 'name phone email' 'file submit accept'; }
  .fast-form .field-submit {
    justify-self: center; } }

@media (min-width: 992px) {
  .fast-form__main {
    align-content: start;
    align-items: start;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: 'name name phone phone email email file file' 'accept accept accept submit submit . . .'; } }

/*==
 *== Additional styles
 *== ======================================= ==*/
.icones {
  float: left; }

.icones img {
  width: auto;
  height: 27px; }

.icones:first-child {
  padding-right: 6px; }

#fixbar {
  position: fixed;
  z-index: 99999;
  left: 50%;
  margin-left: -585px; }

@media (max-width: 1199px) {
  #fixbar {
    position: fixed;
    z-index: 99999;
    left: 50%;
    margin-left: -470px; } }

@media screen and (max-width: 1010px) {
  #fixbar {
    position: fixed;
    z-index: 99999;
    left: 50%;
    margin-left: -380px; } }

@media (max-width: 767px) {
  #fixbar {
    position: fixed;
    z-index: 99999;
    left: 50%;
    margin-left: -364px; } }

/*==
 *== Credit: Nicolas Gallagher and SUIT CSS.
 *== ======================================= ==*/
.embed-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21x9::before {
  padding-top: 42.85714%; }

.embed-responsive-16x9::before {
  padding-top: 56.25%; }

.embed-responsive-4x3::before {
  padding-top: 75%; }

.embed-responsive-1x1::before {
  padding-top: 100%; }

.embed-video {
  border-top: 5px solid #dc6e00;
  background: #000; }

/*==
 *== Form elements
 *== ======================================= ==*/
.form-hint {
  color: #777;
  font-size: 12px;
  font-style: italic;
  line-height: 1.16667; }

.form-label {
  margin-bottom: 3px;
  color: #777;
  font-weight: bold; }

/*==
 *== Main styles
 *== ======================================= ==*/
body {
  position: relative;
  max-width: 100%;
  font-family: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px; }
  body::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  body::-webkit-scrollbar-button {
    display: none; }
  body::-webkit-scrollbar-corner {
    background: transparent; }
  body::-webkit-scrollbar-track {
    background-color: #653215; }
  body::-webkit-scrollbar-thumb {
    background-color: #e97d2a; }
    body::-webkit-scrollbar-thumb:hover {
      background-color: #e97d2a; }
  body::-webkit-resizer {
    display: none; }

.clearfix:after,
.clearfix:before {
  display: table;
  clear: both;
  width: 100%;
  content: ""; }

img::after {
  content: "Изображение не загружено (url: " attr(src) ")";
  display: block;
  font-size: 12px;
  line-height: 1.4;
  border: 1px solid red;
  background: lightpink;
  text-decoration: none;
  color: #000;
  max-width: 100%; }

img,
figure {
  max-width: 100%;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.fa {
  color: inherit; }

.icon-calendar {
  width: 18px;
  height: 18px;
  background-image: url(../img/sprite/sprite.png);
  background-position: 0px 0px; }

.icon-comment {
  width: 18px;
  height: 18px;
  background-image: url(../img/sprite/sprite.png);
  background-position: 0px -20px; }

.icon-mark {
  width: 11px;
  height: 18px;
  background-image: url(../img/sprite/sprite.png);
  background-position: 0px -40px; }

[class^="icon-"] {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle; }

.icon-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-svg--bike {
  width: 1em;
  font-size: 3.2rem; }

.icon-svg--facebook {
  width: 1em;
  font-size: 2rem; }

.icon-svg--tuxedo {
  width: 1em;
  font-size: 3.2rem; }

.icon-svg {
  vertical-align: middle; }

.icon-svg--bike {
  color: red;
  font-size: 50px; }

.icon-svg--tuxedo {
  color: blue;
  font-size: 30px; }

.icon-svg--facebook {
  color: aqua;
  font-size: 30px; }

label.checkbox {
  position: relative;
  width: 100%;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  color: #000;
  font-size: 15px;
  line-height: 1.5em; }

label.checkbox i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 17px;
  text-align: center;
  vertical-align: middle;
  color: transparent;
  border: 1px solid #000;
  border-radius: 3px;
  font-size: 15px;
  line-height: 25px; }

label.checkbox span {
  display: inline-block;
  width: calc(100% - 25px - 17px);
  vertical-align: middle; }

label.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

label.checkbox input:checked ~ i {
  color: #4b84f5;
  background-color: #fff; }

label.radio {
  position: relative;
  width: 100%;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  color: #000;
  font-size: 15px;
  line-height: 1.5em; }

label.radio i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 20px;
  vertical-align: middle;
  border: 1px solid #000;
  border-radius: 50%; }

label.radio span {
  display: inline-block;
  width: calc(100% - 25px - 17px);
  vertical-align: middle; }

label.radio input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

label.radio input:checked ~ i:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 9px;
  height: 9px;
  margin: auto;
  content: "";
  border-radius: 50%;
  background-color: #000; }

*::-webkit-input-placeholder,
*:-moz-placeholder,
*::-moz-placeholder,
*:-ms-input-placeholder {
  opacity: 1; }

/*==
 *== Icon contact
 *== ======================================= ==*/
.icon-contact {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  font-size: 40px;
  font-weight: bold;
  line-height: 1; }
  .icon-contact:hover {
    color: #ed7a20; }

/*==
 *== Header
 *== ======================================= ==*/
.header-section {
  position: fixed;
  z-index: 99800;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(249, 249, 249, 0.95);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0); }
  .header-section .header,
  .header-section .header__caption,
  .header-section .header__phones,
  .header-section .header-button {
    transition: 0.4s ease;
    transition-property: padding, margin, font-size;
    transform-origin: 50% 0; }
  .header-section.isFixed {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3); }
    .header-section.isFixed .header {
      padding-top: 7px;
      padding-bottom: 7px; }
      .header-section.isFixed .header__caption {
        overflow: hidden;
        width: 0;
        height: 0;
        margin-right: 0; }
      .header-section.isFixed .header__phones {
        font-size: 16px; }
      .header-section.isFixed .header__shedule br {
        display: none; }
    .header-section.isFixed .header-button {
      padding: 12px 16px;
      font-size: 12px; }

.header-spacer {
  height: 85px; }
  @media (min-width: 576px) {
    .header-spacer {
      height: 91px; } }
  @media (min-width: 768px) {
    .header-spacer {
      height: 93px; } }
  @media (min-width: 992px) {
    .header-spacer {
      height: 157px; } }
  @media (min-width: 1200px) {
    .header-spacer {
      height: 159px; } }

.header {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px; }
  .header__menu-button {
    flex-shrink: 0;
    margin-right: 20px; }
  .header__logo {
    flex-shrink: 0;
    width: 246px;
    margin-right: 20px; }
    .header__logo > a {
      display: inline-block;
      max-width: 100%;
      vertical-align: top;
      text-decoration: none !important; }
      .header__logo > a > img {
        display: block;
        width: 100%; }
  .header__caption {
    display: none;
    flex-shrink: 0;
    width: 260px;
    margin-right: 20px; }
  .header__contacts {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin-right: 20px; }
  .header__actions {
    margin-left: auto; }
  .header__shedule {
    display: none;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px dotted #ed7a20;
    font-size: 14px;
    line-height: 20px; }
  @media (max-width: 575px) {
    .header__logo {
      width: 216px; }
    .header__actions {
      position: fixed;
      top: 90px;
      right: 0;
      display: flex;
      transform: translateY(-100%) rotate(-90deg);
      transform-origin: 100% 100%;
      opacity: 0.8; }
    .header__contacts {
      justify-content: flex-end;
      margin-right: 0;
      margin-left: auto; } }
  @media (max-width: 374px) {
    .header__logo {
      width: 180px; } }
  @media (min-width: 768px) {
    .header__contact-icon {
      display: none; } }
  @media (min-width: 992px) {
    .header__shedule {
      display: block; } }
  @media (min-width: 1200px) {
    .header__caption {
      display: block; } }
  .header__contacts p {
    margin-bottom: 0; }
  .header__phones {
    display: block;
    text-align: right;
    font-size: 25px;
    line-height: 1.3; }
    .header__phones a {
      text-decoration: none;
      color: #000; }
    .header__phones .email-mobile {
      display: none;
      text-align: center;
      font-size: 16px; }
  @media (max-width: 991px) {
    .header__phones {
      border-width: 0;
      font-size: 22px; }
      .header__phones .email-mobile {
        display: block; } }
  @media (max-width: 767px) {
    .header__phones {
      display: none; } }
  @media (max-width: 991px) {
    .header__phones .hidden-mobile {
      display: none; } }

/*==
 *== Header button
 *== ======================================= ==*/
.header-button {
  display: inline-block;
  padding: 20px 30px;
  user-select: none;
  transition: 0.3s;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff;
  background-color: #ed7a20;
  font-weight: bold; }
  .header-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ca6210; }
  @media (max-width: 991px) {
    .header-button {
      padding: 12px 16px; } }
  @media (max-width: 767px) {
    .header-button {
      padding: 10px 10px;
      font-size: 12px; } }

/*==
 *== Header caption
 *== ======================================= ==*/
.header-caption {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.3; }
  .header-caption__addr {
    font-size: 10px; }
  .header-caption p:last-child {
    margin-bottom: 0; }

.mobile-contact {
  position: relative;
  display: none;
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #eee;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); }

@media (min-width: 768px) {
  .mobile-contact {
    display: none !important; } }

.mobile-contact:before {
  position: absolute;
  top: -17px;
  left: 50%;
  content: "\25b2";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  font-size: 20px; }

.c-yellow {
  color: #ed7a20; }

/*==
 *== Mobile menu & top line
 *== ======================================= ==*/
.line-dashed {
  display: block;
  height: 5px;
  background: #fff url("https://www.izmk.ru/assets/components/themebootstrap/img/poloski.png") repeat-x; }

@media (max-width: 991px) {
  body.isMenuOpen {
    overflow: hidden; }
    body.isMenuOpen > .header-section > .container,
    body.isMenuOpen > .header-section > .mobile-contact,
    body.isMenuOpen > .container {
      pointer-events: none;
      filter: blur(3px); } }

.openbtn {
  display: inline-block;
  cursor: pointer;
  background: none;
  box-shadow: none; }
  .openbtn__iconline {
    display: block;
    width: 30px;
    height: 4px;
    margin: 5px 0;
    border-radius: 2px;
    background: #aaa; }
  .openbtn:hover > span {
    background: #ddd; }
  @media (min-width: 992px) {
    .openbtn {
      display: none; } }

.closebtn {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 15px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  font-size: 36px; }
  .closebtn:hover {
    text-decoration: none;
    color: #fff; }
  .closebtn > span:first-child {
    font-size: 14px; }
  @media (min-width: 992px) {
    .closebtn {
      display: none; } }

.menubar {
  background-color: #d7e5f5; }

.multimenu {
  margin: 0;
  padding: 0;
  list-style: none; }
  .multimenu__item {
    position: relative; }
  .multimenu__link {
    position: relative;
    display: flex;
    color: #337ab7; }
    .multimenu__link:focus, .multimenu__link:hover {
      text-decoration: none;
      color: #000;
      outline: none; }
    .multimenu__link.active {
      color: #fff;
      background: #e97d2a; }
      .multimenu__link.active + .multimenu__toggle {
        color: #fff; }
  .multimenu__title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 48px;
    padding: 5px 15px; }
  .multimenu__toggle {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-left: 0;
    cursor: pointer;
    transform: translateX(0);
    color: black;
    border-left: 1px solid rgba(0, 0, 0, 0.15); }
    .multimenu__toggle i {
      display: block;
      width: 0;
      height: 0;
      border-width: 8px 5px 0;
      border-color: currentColor transparent transparent;
      border-style: solid; }
    .multimenu__toggle:hover {
      background: #fff; }
  @media (min-width: 992px) {
    .multimenu__toggle {
      position: absolute;
      top: 0;
      right: 0;
      width: 6px;
      pointer-events: none;
      color: #337ab7;
      border: none; }
      .multimenu__toggle i {
        border-width: 5px 3px 0; }
    .multimenu .multimenu {
      position: absolute;
      top: 100%;
      left: 0;
      display: none !important;
      overflow-y: auto;
      width: 300px;
      max-height: 90vh;
      max-height: calc(100vh - 50px);
      background: #d7e5f5;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }
      .multimenu .multimenu .multimenu__title {
        min-height: 32px; }
      .multimenu .multimenu .multimenu__togle {
        display: none; } }
  @media (max-width: 991px) {
    .multimenu__item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .multimenu__item.open .multimenu__toggle {
      background: #fff; }
    .multimenu .multimenu {
      display: none;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      background: #fff; } }

@media (min-width: 992px) {
  .navmenu > .multimenu {
    display: flex;
    justify-content: space-between; }
  .navmenu > .multimenu > .multimenu__item:hover .multimenu {
    display: block !important; } }

@media (max-width: 991px) {
  .navmenu {
    position: fixed;
    z-index: 1;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    transition: left 0.5s ease;
    background-color: #d7e5f5; }
    .navmenu.isOpen {
      left: 0;
      box-shadow: 0 0 0 300vw rgba(0, 0, 0, 0.7); }
    .navmenu > .multimenu {
      position: absolute;
      top: 48px;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      overflow-x: hidden;
      overflow-y: auto; }
      .navmenu > .multimenu > .multimenu__item > .multimenu__link {
        text-transform: uppercase; }
      .navmenu > .multimenu .multimenu .multimenu__title {
        min-height: 32px;
        padding-left: 26px; }
      .navmenu > .multimenu .multimenu .multimenu__toggle {
        height: 32px; } }

/*==
 *== Gallery
 *== ======================================= ==*/
.gallery {
  margin-top: 20px;
  margin-bottom: 20px; }
  .gallery__item {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between; }
    @media (min-width: 992px) {
      .gallery__item {
        padding-right: 15px;
        padding-left: 15px; } }
    @media (max-width: 575px) {
      .gallery__item {
        flex-basis: 100%;
        max-width: 100%; }
        .gallery__item:nth-child(n + 2) {
          margin-top: 30px; } }
  @media (min-width: 576px) {
    .gallery:not([data-columns]) .gallery__item,
    .gallery[data-columns="2"] .gallery__item {
      flex-basis: 50%;
      max-width: 50%; }
      .gallery:not([data-columns]) .gallery__item:nth-child(n + 3),
      .gallery[data-columns="2"] .gallery__item:nth-child(n + 3) {
        margin-top: 30px; } }
  @media (min-width: 576px) and (max-width: 991px) {
    .gallery[data-columns="3"] .gallery__item {
      flex-basis: 50%;
      max-width: 50%; }
      .gallery[data-columns="3"] .gallery__item:nth-child(n + 3) {
        margin-top: 30px; } }
  @media (min-width: 992px) {
    .gallery[data-columns="3"] .gallery__item {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
      .gallery[data-columns="3"] .gallery__item:nth-child(n + 4) {
        margin-top: 30px; } }
  @media (min-width: 576px) and (max-width: 991px) {
    .gallery[data-columns="4"] .gallery__item {
      flex-basis: 50%;
      max-width: 50%; }
      .gallery[data-columns="4"] .gallery__item:nth-child(n + 3) {
        margin-top: 30px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .gallery[data-columns="4"] .gallery__item {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
      .gallery[data-columns="4"] .gallery__item:nth-child(n + 4) {
        margin-top: 30px; } }
  @media (min-width: 1200px) {
    .gallery[data-columns="4"] .gallery__item {
      flex-basis: 25%;
      max-width: 25%; }
      .gallery[data-columns="4"] .gallery__item:nth-child(n + 5) {
        margin-top: 30px; } }
  @media (min-width: 576px) and (max-width: 991px) {
    .gallery[data-columns="5"] .gallery__item {
      flex-basis: 50%;
      max-width: 50%; }
      .gallery[data-columns="5"] .gallery__item:nth-child(n + 3) {
        margin-top: 30px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .gallery[data-columns="5"] .gallery__item {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
      .gallery[data-columns="5"] .gallery__item:nth-child(n + 4) {
        margin-top: 30px; } }
  @media (min-width: 1200px) {
    .gallery[data-columns="5"] .gallery__item {
      flex-basis: 20%;
      max-width: 20%; }
      .gallery[data-columns="5"] .gallery__item:nth-child(n + 6) {
        margin-top: 30px; } }
  @media (min-width: 576px) and (max-width: 991px) {
    .gallery[data-columns="6"] .gallery__item {
      flex-basis: 50%;
      max-width: 50%; }
      .gallery[data-columns="6"] .gallery__item:nth-child(n + 3) {
        margin-top: 30px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .gallery[data-columns="6"] .gallery__item {
      flex-basis: 33.33333%;
      max-width: 33.33333%; }
      .gallery[data-columns="6"] .gallery__item:nth-child(n + 4) {
        margin-top: 30px; } }
  @media (min-width: 1200px) {
    .gallery[data-columns="6"] .gallery__item {
      flex-basis: 16.66667%;
      max-width: 16.66667%; }
      .gallery[data-columns="6"] .gallery__item:nth-child(n + 7) {
        margin-top: 30px; } }
  .gallery__title {
    margin-bottom: 10px;
    font-style: italic; }
  .gallery__link, .gallery__box {
    transition: 0.3s ease; }
  .gallery__link {
    position: relative;
    display: block;
    align-self: flex-start;
    width: 100%;
    border: 5px solid white;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #ddd; }
    .gallery__link:hover {
      border-color: orange;
      box-shadow: 0 0 0 1px orange; }
    .gallery__link::before {
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 75%;
      content: ''; }
  .gallery__link:hover .gallery__box {
    background: #eee; }
  .gallery__box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .gallery__img {
    display: block;
    max-width: 100%;
    max-height: 100%; }

/*==
 *== Footer menu
 *== ======================================= ==*/
.footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none; }
  .footer-menu__link {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    color: #fff; }
    .footer-menu__link:hover {
      text-decoration: underline;
      color: #fff; }

/*==
 *== Footer
 *== ======================================= ==*/
.footer {
  margin-top: 0;
  padding-bottom: 40px;
  color: rgba(240, 243, 246, 0.95);
  background: #4e79a3; }
  .footer__nav {
    background: #42678b; }
    @media (max-width: 767px) {
      .footer__nav {
        display: none; } }
  .footer__col {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 30px; }
    @media (min-width: 992px) {
      .footer__col {
        padding-right: 15px;
        padding-left: 15px; } }
    @media (min-width: 576px) and (max-width: 991px) {
      .footer__col:nth-child(1), .footer__col:nth-child(2) {
        flex-basis: 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .footer__col {
        flex-basis: 33.33333%;
        max-width: 33.33333%; } }
  .footer__title {
    display: flex;
    margin-bottom: 15px; }
    .footer__title > span {
      padding: 3px 0;
      color: #fff;
      border-bottom: 3px solid #fa8b1f; }
  .footer__col a {
    padding-right: 20px;
    text-decoration: underline;
    color: inherit; }
    .footer__col a:hover {
      color: #fff; }
  .footer__col p > img {
    opacity: 0.95; }
  .footer__col strong {
    font-size: 22px;
    font-weight: bold; }

.copyright {
  color: #fff; }

/*==
 *== Feedback form section
 *== ======================================= ==*/
.section-heading {
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold; }

.section-feedback-form {
  padding: 50px 0;
  background: #f9f9f9; }
  .section-feedback-form textarea {
    height: 132px; }

/*==
 *== Achievements
 *== ======================================= ==*/
.achievements {
  margin: 30px -35px;
  margin: 30px calc(var(--content-gutter) / -1);
  padding: 30px 35px;
  padding: 30px var(--content-gutter);
  background: url("../img/avievements-bg.jpg") no-repeat 50% 50%;
  background-size: cover; }
  .achievements__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold; }
  .achievements__main {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    justify-content: center;
    margin-top: 30px; }
    @media (min-width: 992px) {
      .achievements__main {
        margin-right: -15px;
        margin-left: -15px; } }
  .achievements__item {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px; }
    @media (min-width: 992px) {
      .achievements__item {
        padding-right: 15px;
        padding-left: 15px; } }
    @media (max-width: 575px) {
      .achievements__item:nth-child(n + 2) {
        margin-top: 30px; } }
    @media (min-width: 576px) and (max-width: 767px) {
      .achievements__item {
        flex-basis: 50%;
        max-width: 50%; }
        .achievements__item:nth-child(n + 3) {
          margin-top: 30px; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .achievements__item {
        flex-basis: 33.33333%;
        max-width: 33.33333%; }
        .achievements__item:nth-child(n + 4) {
          margin-top: 30px; } }
    @media (min-width: 1200px) {
      .achievements__item {
        flex-basis: 20%;
        max-width: 20%; }
        .achievements__item:nth-child(n + 6) {
          margin-top: 30px; } }

.achievement__icon {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto; }

.achievement__title {
  margin-top: 5px;
  text-align: center;
  color: #cc6701;
  font-size: 18px;
  font-weight: bold; }

.achievement__desc {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 1.42857; }

/*==
 *== Reviews
 *== ======================================= ==*/
.reviews:not(:first-child) {
  margin-top: 30px; }

.reviews__item:nth-child(n + 2) {
  margin-top: 30px; }

/*==
 *== Entry
 *== ======================================= ==*/
.entry__image {
  display: flex;
  flex-shrink: 0; }
  .entry__image a {
    max-width: 100%; }
    .entry__image a:hover img {
      border: 1px solid orange;
      background: orange; }
  .entry__image img {
    max-width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px; }

.entry__body {
  flex-grow: 1;
  margin-top: 15px; }

.entry__title {
  margin-bottom: 10px;
  font-size: 20px; }

@media (min-width: 576px) {
  .entry {
    display: flex; }
    .entry__image {
      width: 150px; }
    .entry__body {
      margin-top: 0;
      padding-left: 20px; } }

@media (min-width: 992px) {
  .entry__image {
    width: 250px; }
  .entry__body {
    padding-left: 30px; } }

@media (min-width: 1200px) {
  .entry__image {
    width: 300px; } }

/*==
 *== Advantages
 *== ======================================= ==*/
.advantages {
  margin: 40px -35px;
  margin: 40px calc(var(--content-gutter) / -1); }
  .advantages__main {
    padding: 35px;
    padding: var(--content-gutter);
    background: url("../img/advantages-bg.jpg") no-repeat 50% 0;
    background-size: cover; }
  .advantages__list {
    margin-top: 20px; }

.advantage-bottom {
  position: relative;
  padding: 52.5px 35px;
  padding: calc(var(--content-gutter) * 1.5) var(--content-gutter);
  background: #feba02 radial-gradient(circle, #feba02, #fa8b1f); }
  .advantage-bottom::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    content: '';
    pointer-events: none;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.37) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.37) 50%, rgba(255, 255, 255, 0.37) 75%, transparent 75%, transparent);
    background-size: 16px 16px;
    background-position: 50% 0; }
  .advantage-bottom__item {
    display: flex;
    align-items: center; }
  .advantage-bottom__icon {
    flex-shrink: 0;
    width: 70px; }
  .advantage-bottom__box {
    flex-grow: 1;
    padding-right: 20px;
    padding-left: 30px; }
  .advantage-bottom__text1 {
    font-size: 20px;
    line-height: 1.25; }
  .advantage-bottom__text2 {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1.25; }
  @media (max-width: 991px) {
    .advantage-bottom__item:nth-child(n + 2) {
      margin-top: 30px; } }

/*==
 *== Warranty block
 *== ======================================= ==*/
.warranty-block {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 25px;
  padding-right: 35px;
  padding-right: var(--content-gutter);
  padding-bottom: 25px;
  padding-left: 35px;
  padding-left: var(--content-gutter);
  background: rgba(254, 182, 4, 0.1); }
  .warranty-block::before, .warranty-block::after {
    position: absolute;
    right: 0;
    left: 0;
    height: 5px;
    content: '';
    pointer-events: none;
    background-image: linear-gradient(135deg, #fae1a8 25%, transparent 25%, transparent 50%, #fae1a8 50%, #fae1a8 75%, transparent 75%, transparent);
    background-size: 16px 16px;
    background-position: 50% 0; }
  .warranty-block::before {
    top: 0; }
  .warranty-block::after {
    bottom: 0; }
  .warranty-block__icon {
    flex-shrink: 0;
    padding-right: 35px;
    padding-right: var(--content-gutter); }
  .warranty-block__img {
    width: auto;
    height: 60px;
    vertical-align: top; }
  .warranty-block__content {
    flex-grow: 1; }
  .warranty-block__title {
    text-transform: uppercase;
    color: #cc6701;
    font-size: 30px;
    font-weight: bold;
    line-height: 0.86667; }
  .warranty-block__text {
    font-size: 18px;
    line-height: 1.44444; }
  @media (max-width: 767px) {
    .warranty-block {
      display: block; }
      .warranty-block__content {
        margin: 10px 0; } }

/*==
 *== Stages of order
 *== ======================================= ==*/
.stages {
  position: relative;
  margin: 10px -35px;
  margin: 10px calc(var(--content-gutter) / -1);
  padding: 30px 35px;
  padding: 30px var(--content-gutter);
  background: #eef0f6 url("../img/stages-bg.jpg") center;
  background-size: cover; }
  .stages::before, .stages::after {
    background-image: linear-gradient(135deg, #e0e7f0 25%, transparent 25%, transparent 50%, #e0e7f0 50%, #e0e7f0 75%, transparent 75%, transparent);
    background-size: 16px 16px;
    background-position: 50% 0;
    position: absolute;
    right: 0;
    left: 0;
    height: 5px;
    content: ''; }
  .stages::before {
    top: 0; }
  .stages::after {
    bottom: 0; }
  .stages__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px; }
  .stages__item {
    flex-shrink: 0;
    margin-top: 30px; }

.stage {
  position: relative;
  width: 130px; }
  .stage::before {
    position: absolute;
    width: 72px;
    height: 72px;
    content: '';
    border-radius: 100%;
    background: #fff; }
  .stage__index {
    position: absolute;
    top: -12px;
    left: 0;
    width: 72px;
    text-align: center;
    color: #fc9f12;
    font-family: "PT Sans", sans-serif;
    font-size: 60px;
    font-weight: bold;
    line-height: 0.5; }
  .stage__title {
    position: relative;
    width: 130px;
    padding-top: 36px;
    font-size: 16px;
    line-height: 1.3125; }

/*==
 *== Card
 *== ======================================= ==*/
.card {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid orange;
  border-radius: 5px;
  background: #fdebcd; }
  .card__imgbox {
    position: relative;
    display: block;
    text-decoration: none; }
    .card__imgbox::before {
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 75%;
      content: ''; }
    .card__imgbox > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid orange;
      border-radius: 2px;
      object-fit: cover; }
  .card__content {
    padding: 5px 0 0;
    font-size: 15px; }
    .card__content p {
      margin: 3px 0 0; }
      .card__content p > span {
        opacity: 0.7;
        font-size: 0.9em; }
  .card__title {
    padding: 3px 0;
    font-weight: bold; }
    .card__title > a {
      text-decoration: underline;
      color: #c96651; }
      .card__title > a:hover {
        text-decoration: underline;
        color: #23527c; }

/*==
 *== Objects
 *== ======================================= ==*/
.objects__item {
  display: flex;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .objects__item {
      margin-bottom: 30px; } }

/*==
 *== Object description
 *== ======================================= ==*/
.object-description {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 18px; }
  .object-description p {
    margin: 3px 0 0; }
    .object-description p:first-child {
      margin-top: 0; }
    .object-description p > span {
      opacity: 0.7;
      font-size: 0.9em; }

/*==
 *== Photo gallery
 *== ======================================= ==*/
.photo-gallery {
  border: 1px solid red;
  background: pink; }
  .photo-gallery::before {
    content: '@deprecated .photo-gallery'; }

/*==
 *== Years menu
 *== ======================================= ==*/
.years {
  margin-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center; }
  .years:last-child {
    margin-top: 30px;
    margin-bottom: 0; }
  .years__list {
    display: inline-flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    vertical-align: top;
    border-radius: 5px;
    background: #2e5f7a; }
  .years__link {
    display: block;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 17px; }
    .years__link:active, .years__link:focus {
      text-decoration: none;
      color: #fff; }
  .years__item_active .years__link, .years__link:hover {
    text-decoration: none;
    color: #fff;
    background: #6091ac; }
  @supports (display: grid) {
    .years__list {
      display: grid;
      max-width: 960px;
      margin: auto;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); } }

/*==
 *== News item
 *== ======================================= ==*/
.news-entry__header {
  position: relative;
  display: flex;
  align-items: baseline;
  width: 100%;
  padding: 8px 0; }
  .news-entry__header::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 4px;
    content: '';
    background-image: linear-gradient(135deg, #fccd31 35%, transparent 35%, transparent 50%, #fccd31 50%, #fccd31 85%, transparent 85%, transparent);
    background-size: 16px 16px;
    background-position: 50% 0; }

.news-entry__header-link {
  text-decoration: none !important;
  color: #930; }

.news-entry__header-link:hover .news-entry__title {
  text-decoration: underline; }

.news-entry__date {
  display: inline-block;
  flex-shrink: 0;
  color: orange;
  font-size: 16px;
  font-weight: bold; }

.news-entry__title {
  display: block;
  margin: 0;
  font-size: 18px;
  font-weight: bold; }

@media (max-width: 575px) {
  .news-entry__side {
    margin-top: 10px; }
  .news-entry__content {
    margin-top: 15px; } }

@media (min-width: 576px) {
  .news-entry {
    display: flex;
    flex-wrap: wrap; }
    .news-entry__header {
      border-bottom: 1px solid #ebebeb; }
    .news-entry__header-link {
      display: flex;
      align-items: baseline; }
    .news-entry__title {
      padding: 0 0 0 20px; }
    .news-entry__side {
      flex-basis: 33.33333%;
      width: 33.33333%;
      max-width: 33.33333%; }
    .news-entry__content {
      flex-basis: 66.66667%;
      width: 66.66667%;
      max-width: 66.66667%;
      padding-top: 10px;
      padding-right: 30px;
      padding-left: 20px; }
      .news-entry__content p:last-child {
        margin-bottom: 0; } }

@media (min-width: 992px) {
  @supports (display: grid) {
    .news-entry {
      position: relative;
      display: grid;
      align-content: start;
      column-gap: 30px;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto 1fr;
      grid-template-areas: 'side header' 'side content'; }
      .news-entry__header {
        grid-area: header;
        position: static; }
      .news-entry__title {
        order: -1;
        padding: 0 20px 0 0; }
      .news-entry__content {
        grid-area: content;
        flex-basis: auto;
        width: auto;
        max-width: none;
        padding-left: 0; }
      .news-entry__side {
        grid-area: side;
        flex-basis: auto;
        width: auto;
        max-width: none;
        padding-top: 4px; }
      .news-entry__cover {
        display: block;
        width: 100%; }
        .news-entry__cover img {
          display: block;
          width: 100%; } } }

/*==
 *== News page
 *== ======================================= ==*/
.news-list__item + .news-list__item {
  margin-top: 30px; }

/*==
 *== Prompt
 *== ======================================= ==*/
.prompt {
  margin-right: -35px;
  margin-right: calc(var(--content-gutter) * -1);
  margin-bottom: 35px;
  margin-left: -35px;
  margin-left: calc(var(--content-gutter) * -1);
  padding: 15px 35px;
  padding: 15px var(--content-gutter);
  background: #f7f7f7;
  font-style: italic; }
  .prompt p:last-child {
    margin-bottom: 0; }
  .prompt a {
    text-decoration: underline;
    color: #d16636; }
  .page-content > .prompt:first-child {
    margin-top: -20px; }

/*==
 *== Article
 *== ======================================= ==*/
.article {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 18px; }
  .article > :last-child {
    margin-bottom: 0; }

/*==
 *== Article item of list
 *== ======================================= ==*/
.article-item__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.article-item__icon {
  position: relative;
  flex-shrink: 0;
  width: 40px;
  height: 40px; }
  .article-item__icon img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 45px;
    max-width: none;
    height: 45px; }

.article-item__title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-height: 40px;
  margin: 0 0 0 20px;
  padding: 8px 0 10px;
  color: #930;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  font-size: 18px;
  font-weight: bold; }
  .article-item__title a {
    color: inherit; }

.article-item__footer {
  margin-top: 15px; }

/*==
 *== Title
 *== ======================================= ==*/
.all-title {
  margin-bottom: 20px;
  color: #930;
  font-size: 20px;
  font-weight: bold; }

/*==
 *== Projects table
 *== ======================================= ==*/
#projects-table {
  min-width: 1140px; }
  #projects-table .jsgrid-cell {
    font-size: 12px; }
  #projects-table .jsgrid-header-cell {
    font-size: 12px; }
    #projects-table .jsgrid-header-cell.jsgrid-header-sortable {
      position: relative; }
      #projects-table .jsgrid-header-cell.jsgrid-header-sortable.jsgrid-header-sort::before {
        position: absolute;
        bottom: 8px;
        left: 50%;
        margin-left: -2px; }
  #projects-table .jsgrid-filter-row .jsgrid-cell {
    padding: 1px; }
    #projects-table .jsgrid-filter-row .jsgrid-cell select,
    #projects-table .jsgrid-filter-row .jsgrid-cell input[type=text] {
      height: 20px;
      padding: 0;
      text-indent: 10px;
      border: 1px solid #d8d8d8;
      background: #fff; }
      #projects-table .jsgrid-filter-row .jsgrid-cell select:focus,
      #projects-table .jsgrid-filter-row .jsgrid-cell input[type=text]:focus {
        border-color: #d7e3f4;
        outline: none !important; }
    #projects-table .jsgrid-filter-row .jsgrid-cell select {
      text-indent: 0;
      background: linear-gradient(to bottom, #f5f5f5, #f1f1f1); }

.projects-table-outer {
  overflow: auto;
  margin-right: -35px;
  margin-right: calc(var(--content-gutter) / -1);
  margin-left: -35px;
  margin-left: calc(var(--content-gutter) / -1); }
  .projects-table-outer::-webkit-scrollbar {
    width: 8px;
    height: 8px; }
  .projects-table-outer::-webkit-scrollbar-button {
    display: none; }
  .projects-table-outer::-webkit-scrollbar-corner {
    background: transparent; }
  .projects-table-outer::-webkit-scrollbar-track {
    background-color: rgba(255, 165, 0, 0.5); }
  .projects-table-outer::-webkit-scrollbar-thumb {
    background-color: orange; }
    .projects-table-outer::-webkit-scrollbar-thumb:hover {
      background-color: orange; }
  .projects-table-outer::-webkit-resizer {
    display: none; }

/*==
 *== Contacts page
 *== ======================================= ==*/
.contacts-page {
  position: relative; }
  .contacts-page::before {
    position: absolute;
    top: -70px;
    left: 50%;
    width: 311px;
    height: 96px;
    margin-left: -420px;
    content: '';
    pointer-events: none;
    background: url("/assets/components/themebootstrap/img/bg_pen.png") no-repeat;
    background-size: contain; }

/*==
 *== Contact block
 *== ======================================= ==*/
.contact-info {
  padding: 30px 0; }
  .contact-info_first {
    padding-top: 0;
    background: url("../img/bg_pagetitle.png") no-repeat 50% 100%; }
  .contact-info_middle {
    background: url("../img/bg_conmiddle.png") no-repeat 0 0; }
  .contact-info__title {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    text-align: center; }
    .contact-info__title span {
      padding: 3px 6px;
      color: #000;
      background: #fa8b1f; }
    .contact-info__title_center {
      justify-content: center; }
  .contact-info__content_inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around; }
  .contact-info__address {
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 15px; }
    .contact-info__address::before {
      display: inline-block;
      flex-shrink: 0;
      width: 15px;
      height: 20px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_point.png"); }
  .contact-info__phone {
    display: flex;
    align-items: center;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
    color: #ff8207;
    font-size: 19px;
    font-weight: bold; }
    .contact-info__phone::before {
      display: inline-block;
      flex-shrink: 0;
      width: 10px;
      height: 26px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_conphone.png"); }
  .contact-info__email-box {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    padding: 0 10px; }
    .contact-info__email-box > * {
      margin: 0 10px; }
  .contact-info__email {
    display: flex;
    align-items: center; }
    .contact-info__email_inline {
      display: inline-flex;
      vertical-align: baseline; }
    .contact-info__email::before {
      display: inline-block;
      flex-shrink: 0;
      width: 17px;
      height: 14px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_conmail.png"); }
    .contact-info__email a {
      text-decoration: none;
      color: #000; }
      .contact-info__email a:hover {
        text-decoration: underline;
        color: orange; }
  .contact-info__skype {
    display: flex;
    align-items: center; }
    .contact-info__skype::before {
      display: inline-block;
      flex-shrink: 0;
      width: 15px;
      height: 14px;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background: url("../img/bg_conskype.png"); }
    .contact-info__skype a {
      text-decoration: none;
      color: #000; }
      .contact-info__skype a:hover {
        text-decoration: underline;
        color: orange; }

/*==
 *== Map in contacts
 *== ======================================= ==*/
.contacts-map {
  min-height: 400px;
  background: #ddd; }

/*==
 *== Typical project card
 *== ======================================= ==*/
.tp-card__main {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    .tp-card__main {
      margin-right: -15px;
      margin-left: -15px; } }

.tp-card__photo {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  position: relative; }
  @media (min-width: 992px) {
    .tp-card__photo {
      padding-right: 15px;
      padding-left: 15px; } }

.tp-card__img {
  display: block;
  width: 100%;
  height: auto;
  border: 5px solid white;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #ddd; }

.tp-card__info {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px; }
  @media (min-width: 992px) {
    .tp-card__info {
      padding-right: 15px;
      padding-left: 15px; } }

.tp-card__description > :first-child {
  margin-top: 0 !important; }

.tp-card__shop {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem; }

.tp-card__price {
  font-size: 20px; }
  .tp-card__price span {
    color: #cc6701;
    font-size: 30px; }

.tp-card__price, .tp-card__action {
  margin-bottom: 2rem; }

@media (min-width: 768px) {
  .tp-card__photo {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .tp-card__info {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

.animate {
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.dev {
  padding: 0.1em 1em;
  color: black;
  border: 1px solid red;
  background: pink;
  font-size: 12px;
  line-height: 1.2; }
  .dev-mode .dev {
    display: block; }

.dev-mode .container {
  background: linear-gradient(to right, rgba(255, 192, 203, 0.5) 10px, transparent 10px, transparent calc(100% - 10px), rgba(255, 192, 203, 0.5) calc(100% - 10px)); }
  @media (min-width: 992px) {
    .dev-mode .container {
      background: linear-gradient(to right, rgba(255, 192, 203, 0.5) 15px, transparent 15px, transparent calc(100% - 15px), rgba(255, 192, 203, 0.5) calc(100% - 15px)); } }

.dev-mode .inner,
.dev-mode .page-content {
  background-image: linear-gradient(to right, rgba(194, 222, 183, 0.5) var(--content-gutter), transparent var(--content-gutter), transparent calc(100% - var(--content-gutter)), rgba(194, 222, 183, 0.5) calc(100% - var(--content-gutter))); }
