/*==
 *== Typical projects
 *== ======================================= ==*/

.typical-block {
  position: relative;
  padding-top: $section-top-padding;
  padding-bottom: $section-top-padding;
  background: (
    url("/assets/components/themebootstrap/img/section-divider.png") no-repeat 50% 0,
    url("/assets/components/themebootstrap/img/typical-bg-top-left.gif") no-repeat 0 0,
    url("/assets/components/themebootstrap/img/typical-bg-bottom-right.gif") no-repeat 100% 100%,
    white
  );


  &__header {
    margin-bottom: $section-top-padding;
  }

  &__content {
    padding: 0 $section-side-padding;

    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }
}

.typical {
  $typical-gutter-xs: 10px;
  $typical-gutter: 22px;

  @include make-row($typical-gutter-xs);

  &__item {
    @include make-col-ready($typical-gutter-xs);
    @include make-col(1, 2);
    display: flex;

    > * {
      width: 100%;
    }
  }

  @include media-breakpoint-down(xs) {
    &__item:nth-child(n + 3) {
      margin-top: $typical-gutter-xs;
    }
  }

  @include media-breakpoint-up(mb) {
    @include make-row($typical-gutter);
    &__item {
      @include make-col-ready($typical-gutter);
      @include make-col(1, 2);
    }
  }
  @include media-breakpoint-only(mb) {
    &__item:nth-child(n + 3) {
      margin-top: $typical-gutter;
    }
  }

  @include media-breakpoint-up(sm) {
    &__item {
      @include make-col(1, 3);
    }
    &__item:nth-child(n + 4) {
      margin-top: $typical-gutter;
    }
  }

  @include media-breakpoint-up(xl) {
    &__item {
      @include make-col(1, 6);
    }
    &__item:nth-child(n + 4) {
      margin-top: 0;
    }
    &__item:nth-child(n + 7) {
      margin-top: $typical-gutter;
    }
  }
}

.typical-project {
  position: relative;

  &, a {
    text-decoration: none !important;
  }

  &::after {
    position: absolute;
    right: -1px;
    bottom: -1px;
    left: -1px;
    height: 5px;
    content: '';
    $strip: #d2d7e2;
    background-image: linear-gradient(135deg, $strip 35%, transparent 35%, transparent 50%, $strip 50%, $strip 85%, transparent 85%, transparent);
    background-position: 50% 0;
    background-size: 16px 16px;
  }

  &__cover {
    display: block;
    max-width: 100%;
    height: 110px;
    margin: auto;
    padding: 14px;
    object-fit: contain;
  }

  &__title {
    padding: 5px 10px;
    text-transform: uppercase;
    color: #d37b23;
    border-top: 3px solid transparent;
    font-size: 13px;
    font-weight: bold;
  }

  &__desc {
    padding: 5px 10px;
    color: #000;
  }

  &:hover &__title {
    border-top-color: #ff7f00;
  }

  &:hover &__desc {
    color: #ff7f00;
  }

  @include media-breakpoint-up(sm) {
    &__title {
      font-size: 16px;
    }
  }
}
