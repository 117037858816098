/*==
 *== Projects
 *== ======================================= ==*/

.projects {
  position: relative;
  padding: $section-top-padding 0;
  background: url('/assets/components/themebootstrap/img/bg_project.jpg') no-repeat 50% 0;
  background-size: cover;

  &__container {
    padding: 0 ($section-side-padding / 2);
  }

  &__content {
    margin-top: 20px;
  }

  &__decoration-line {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .owl-nav {
    color: #fff;
  }

  @include media-breakpoint-down(sm) {
    @include make-margins();
  }

  @include media-breakpoint-up(md) {
    &__container {
      padding: 0 $section-side-padding;
    }
  }
}

.project-slide {
  margin: 0 7px;
  padding: 0;
  background-color: #fff;
  box-shadow: 3px 0 5px -3px rgba(#323232, 0.75), -3px 0 5px -3px rgba(#323232, 0.75);

  &__cover {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: percentage(116/156);

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -10px;
      content: '';
      pointer-events: none;
      border: 10px solid transparent;
      border-top-color: #fc8219;
    }
  }

  &__title {
    margin-top: 10px;
    padding: 10px;
    color: #d3802d;
    font-size: 14px;
    line-height: 120%;

    a {
      display: block;
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
