/*==
 *== Striped line
 *== ======================================= ==*/

.strip-line {
  z-index: $z-lines;
  width: 100%;
  height: 5px;
  pointer-events: none;
  opacity: 0.75;
  background: url('/assets/components/themebootstrap/img/poloski.png') repeat-x;
}

// todo: deprecated
.poloski {
  @extend %deprecated;
  position: absolute;
  z-index: $z-lines;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 5px;
  opacity: 0.75;
  background: url('/assets/components/themebootstrap/img/poloski.png') repeat-x;
}
