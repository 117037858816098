/*==
 *== Form elements
 *== ======================================= ==*/

.form-hint {
  color: #777;
  font-size: 12px;
  font-style: italic;
  line-height: (14/12);
}

.form-label {
  margin-bottom: 3px;
  color: #777;
  font-weight: bold;
}
